import { call, all, put, select } from 'redux-saga/effects'
import { takeLatest } from '@redux-saga/core/effects'
import referralActionTypes from "./referralActionTypes";
import { network } from "../network";
import { toast } from 'utils/toast'

function* fetchOffer(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/referral/offer`,
      method: 'GET',
    })

    yield put({
      type: referralActionTypes.REFERRAL_FETCH_OFFER_FULFILLED,
      payload: {
        offer: response.data,
      },
    })
  } catch (err) {
    

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: referralActionTypes.REFERRAL_FETCH_OFFER_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "ReferralOfferNotFound") {
      yield put({
        type: referralActionTypes.REFERRAL_FETCH_OFFER_FAIL,
        payload: err.response.data
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: referralActionTypes.REFERRAL_FETCH_OFFER_FAIL,
        payload: err.response.data
      })
      return
    }

    throw err
  }
}

function* updateOffer(action) {
  const { payload } = action

  const state = yield select()
  const { offer } = state.referral
  const stateVisible = offer.data?.offer[0].visible
  const payloadVisible = payload.data.visible
  const stateTelegramChatID = offer.data?.offer[0].telegram_chat_id
  const payloadTelegramChatID = payload.data.telegram_chat_id
  
  const toastParams = {}

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/referral/offer`,
      method: 'PATCH',
      data: payload.data
    })

    yield put({
      type: referralActionTypes.REFERRAL_UPDATE_OFFER_FULFILLED,
      payload: {
        offer: {
          offer: [response.data]
        },
      },
    })

    if (stateVisible !== payloadVisible) {
      toastParams.title = payloadVisible ? 'Реферальная программа открыта' : 'Реферальная программа скрыта'
      toastParams.text = payloadVisible ? 'Реферальная программа отображается в приложении водителей' : 'Реферальная программа не отображается в приложении водителей. Попробуйте повторить попытку позже'
      toastParams.color = 'primary'
    }

    if (stateTelegramChatID != payloadTelegramChatID) {
      toastParams.title = payloadTelegramChatID ? 'Телеграм-уведомления подключены' : 'Телеграм-уведомления отключены'
      toastParams.text = 'Условия реферальной программы успешно обновлены'
      toastParams.color = 'primary'
    }

    toast.add({
      title: toastParams.title || 'Условия обновлены',
      text: toastParams.text || 'Условия реферальной программы успешно обновлены',
      color: toastParams.color || 'success',
    })

  } catch (err) {
    if (stateVisible !== payloadVisible) {
      toastParams.title = 'Не удалось'
      toastParams.text = payloadVisible ? 'Реферальная программа не отображается в приложении водителей. Попробуйте повторить попытку позже' : 'Реферальная программа отображается в приложении водителей. Попробуйте повторить попытку позже'
    }

    const alertError = () => {
      toast.add({
        title: toastParams.title || 'Условия не обновлены',
        text: toastParams.text || 'Произошла ошибка, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: referralActionTypes.REFERRAL_UPDATE_OFFER_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "ReferralOfferNotFound") {
      yield put({
        type: referralActionTypes.REFERRAL_UPDATE_OFFER_FAIL,
        payload: err.response.data
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: referralActionTypes.REFERRAL_UPDATE_OFFER_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: referralActionTypes.REFERRAL_UPDATE_OFFER_FAIL,
        payload: err.response.data
      })
      alertError()
      return
    }

    throw err
  }
}

function* deleteOffer(action) {
  const {id} = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/referral/offer/${id}`,
      method: 'DELETE',
    })

    yield put({
      type: referralActionTypes.REFERRAL_DELETE_OFFER_FULFILLED,
      payload: {},
    })

    toast.add({
      title: 'Программа остановлена',
      text: 'Все необработанные заявки будут удалены',
      color: 'primary',
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось',
        text: 'Реферальная программа активна. Попробуйте попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: referralActionTypes.REFERRAL_DELETE_OFFER_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "ReferralOfferNotFound") {
      yield put({
        type: referralActionTypes.REFERRAL_DELETE_OFFER_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: referralActionTypes.REFERRAL_DELETE_OFFER_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: referralActionTypes.REFERRAL_DELETE_OFFER_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* createOffer(action) {
  const {payload} = action
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/referral/offer`,
      method: 'POST',
      data: payload.data
    })

    yield put({
      type: referralActionTypes.REFERRAL_CREATE_OFFER_FULFILLED,
      payload: {
        offer: {
          offer: [response.data]
        },
      },
    })

    toast.add({
      title: 'Реферальная программа запущена',
      text: 'Информация о приглашениях будет доступна в разделе "Новые заявки"',
      color: 'primary',
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось',
        text: 'Реферальная программа не запущена. Повторите попытку позже.',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: referralActionTypes.REFERRAL_CREATE_OFFER_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "ReferralOfferAlreadyExists") {
      yield put({
        type: referralActionTypes.REFERRAL_CREATE_OFFER_FAIL,
        payload: err.response.data
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: referralActionTypes.REFERRAL_CREATE_OFFER_FAIL,
        payload: err.response.data
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: referralActionTypes.REFERRAL_CREATE_OFFER_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}


function* fetchList(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/referral/invite/list`,
      method: 'POST',
    })

    yield put({
      type: referralActionTypes.REFERRAL_FETCH_LIST_FULFILLED,
      payload: {
        inviteList: response.data
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: referralActionTypes.REFERRAL_FETCH_LIST_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: referralActionTypes.REFERRAL_FETCH_LIST_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}


export default function* referralSagas() {
  yield all([
    yield takeLatest(referralActionTypes.REFERRAL_FETCH_OFFER_REQUEST, fetchOffer),
    yield takeLatest(referralActionTypes.REFERRAL_UPDATE_OFFER_REQUEST, updateOffer),
    yield takeLatest(referralActionTypes.REFERRAL_DELETE_OFFER_REQUEST, deleteOffer),
    yield takeLatest(referralActionTypes.REFERRAL_CREATE_OFFER_REQUEST, createOffer),
    yield takeLatest(referralActionTypes.REFERRAL_FETCH_LIST_REQUEST, fetchList),
  ])
}
