import React from 'react';
import styled from 'styled-components';
import {
  EuiCard,
  EuiIcon,
  EuiBadge,
} from '@elastic/eui';

const Wrapper = styled(EuiCard)`
  background-color: ${({ theme }) => theme.colors.colorEmptyShade};
  box-shadow: ${({ theme }) => theme.boxShadows.bottomLight};
  padding: 20px 16px;
  .euiCard__children {
    margin-top: 4px;
    line-height: 21px;
  }
  .euiCard__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .euiCard__top + .euiCard__content {
    margin-top: 22px;
  }
  .euiTitle {
    font-size: 19px;
  }
  .euiCard__top .euiCard__icon {
    margin-top: 0;
  }
  
  @media ${({ theme }) => theme.media.phone} {
    .euiCard__top {
      flex-direction: row-reverse
    }
    .euiCard__top + .euiCard__content {
      margin-top: 0;
    }
  }
`;

const Badge = styled(EuiBadge)`
  font-weight: 500;
`;

export const IconBadgeCard = (props) => {
  const { className, title, icon, body, footer, badgeText } = props
  return (
    <Wrapper
      className={className}
      icon={(icon || badgeText) ? (
        <>
          {icon && <EuiIcon size='original' type={icon} />}
          {badgeText && <Badge className='cardBadge' color=''>{badgeText}</Badge>}
        </>
      ) : null}
      title={title}
      textAlign='left'
      paddingSize='none'
      display
      children={body}
      footer={footer}
    />
  )
}
