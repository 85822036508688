import React from 'react';
import styled from "styled-components";
import {
  EuiPageBody,
} from '@elastic/eui';
import NotFound from 'components/NotFound/NotFound';
import connectionPrimaryIcon from 'assets/images/connection-primary.svg'
import { PrimaryEmptyButton } from 'components/Buttons';
import { useRouter} from 'utils/useRouter'

const PageBody = styled(EuiPageBody)`
  background-color: ${({theme}) => theme.colors.colorEmptyShade};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh / var(--page-zoom));
`;

const Server = styled(NotFound)`
  padding: 90px 20px 90px;
  .euiText {
    max-width: 318px;
    margin-bottom: 40px;
  }
  @media ${({ theme }) => theme.media.phone} {
    .euiIcon {
      max-width: 56px;
    }
    .euiText {
      margin: 8px auto 18px;
    }
  }
`;

export function ServerError() {
  const router = useRouter()
  const onBack = () => router.history.push('/login')
  return (
    <PageBody>
      <Server 
        icon={connectionPrimaryIcon}
        title='Сервис недоступен'
        description='Мы уже в курсе и работаем над исправлением. Приносим извинения за временные неудобства.'
        footer={
          <PrimaryEmptyButton
            onClick={onBack}
          >
            Вернуться на главную
          </PrimaryEmptyButton>
        }
      />
    </PageBody>
  );
}

