import React, { useEffect, useRef, useState } from "react";
import { EuiGlobalToastList } from "@elastic/eui";
import styled from "styled-components";

const GlobalToastList = styled(EuiGlobalToastList)`
  .euiToast {
    box-shadow: 0px 2.7px 9px rgba(109, 152, 184, 0.13), 0px 9.4px 24px rgba(109, 152, 184, 0.09), 0px 21.8px 43px rgba(109, 152, 184, 0.08);
  }
  .euiToast--success {
    border-color: ${({theme}) => theme.colors.colorSuccessText};
  }
  .euiToastHeader {
    padding-right: 16px;
  }
`;

const toast = {
  add: (toast) => {
    // todo: error?
    // throw new Error('toast component not mounted')

    // title ReactNode
    // color "primary", "success", "warning", "danger"
    // iconType IconType
    // onClose () => void
    // className string
    // aria-label string
    // data-test-subj string
  },
  colors: {
    info: 'info',
    success: 'success',
    warning: 'warning',
    danger: 'danger',
  },
}

let lastIndex = 0

function Toasts() {
  const [toastsList, setToastsList] = useState([]);

  const addToast = (options) => {
    const key = `key-${lastIndex++}`
    const toast = {
      id: key,
      ...options,
    }
    setToastsList((toasts) => [...toasts, toast]);
  }

  useEffect(() => {
    toast.add = addToast
  }, [])

  const removeToast = (removedToast) => {
    setToastsList(toastsList.filter((toast) => toast.id !== removedToast.id));
  };

  return (
    <GlobalToastList
      toasts={toastsList}
      dismissToast={removeToast}
      toastLifeTimeMs={5000}
    />
  )
}

export { Toasts, toast }
