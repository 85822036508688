import { useState, useEffect } from 'react';

export const breakpoints = {
  isMobile: window.matchMedia('only screen and (max-width: 768px)').matches,
  isMobileSmall: window.matchMedia('only screen and (max-width: 360px)').matches,
  isMobileLarge: window.matchMedia('(min-width: 361px) and (max-width: 768px)').matches,
  isTablet: window.matchMedia('(min-width: 769px) and (max-width: 1024px)').matches,
  isDesktopSmall: window.matchMedia('(min-width: 1025px) and (max-width: 1220px)').matches,
  isDesktopMedium: window.matchMedia('(min-width: 1221px) and (max-width: 1365px)').matches,
  isDesktop: window.matchMedia('(min-width: 1366px)').matches,
}

export function useBreakpoints() {
  const [resolutions, setResolutions] = useState({
    isMobile: window.matchMedia('only screen and (max-width: 768px)').matches,
    isMobileSmall: window.matchMedia('only screen and (max-width: 360px)').matches,
    isMobileLarge: window.matchMedia('(min-width: 361px) and (max-width: 768px)').matches,
    isTablet: window.matchMedia('(min-width: 769px) and (max-width: 1024px)').matches,
    isDesktopSmall: window.matchMedia('(min-width: 1025px) and (max-width: 1220px)').matches,
    isDesktopMedium: window.matchMedia('(min-width: 1221px) and (max-width: 1365px)').matches,
    isDesktop: window.matchMedia('(min-width: 1366px)').matches,
  })

  const updateResolutions = () => {
    setResolutions({
      isMobile: window.matchMedia('only screen and (max-width: 768px)').matches,
      isMobileSmall: window.matchMedia('only screen and (max-width: 360px)').matches,
      isMobileLarge: window.matchMedia('(min-width: 361px) and (max-width: 768px)').matches,
      isTablet: window.matchMedia('(min-width: 769px) and (max-width: 1024px)').matches,
      isDesktopSmall: window.matchMedia('(min-width: 1025px) and (max-width: 1220px)').matches,
      isDesktopMedium: window.matchMedia('(min-width: 1221px) and (max-width: 1365px)').matches,
      isDesktop: window.matchMedia('(min-width: 1366px)').matches,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', updateResolutions)
    return () => {
      window.removeEventListener('resize', updateResolutions)
    }
  }, [])

  return resolutions
}

