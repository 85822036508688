import React, { useState } from 'react';
import styled from 'styled-components';
import {
  EuiButtonIcon,
  EuiCallOut
} from '@elastic/eui';

const Wrapper = styled(EuiCallOut)`
  border-radius: 6px;
  padding: 16px 12px;
  .euiCallOutHeader__title {
    width: 100%;
  }
  svg.euiCallOutHeader__icon {
    transform: translateY(3px);
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  @media ${props => props.theme.media.phone} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const Close = styled(EuiButtonIcon)`
  color: inherit;
  &.euiButtonIcon {
    &:focus-within, &:hover {
      background-color: transparent;
    }
  }
  @media ${props => props.theme.media.phone} {
    margin-right: -5px;
  }
`;

export default (props) => {
  const { icon, className, title, message, onClose, isVisible = true } = props
  return (
    <>
      {isVisible &&
        <Wrapper
          className={className}
          iconType={icon}
          title={
            <Title>
              {title}
              {onClose &&
                <Close
                  iconType='cross'
                  aria-label='cross'
                  onClick={onClose} />
              }
            </Title>
          }>
          {message}
        </Wrapper>
      }
    </>
  )
}