import React, {Suspense} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from "styled-components";
import {EuiPageBody} from "@elastic/eui";

const PageBody = styled(EuiPageBody)`
  padding: 40px 32px 44px;
  margin-top: 48px;
  background-color: ${({ theme }) => theme.colors.colorEmptyShade};
  @media ${({ theme }) => theme.media.phone} {
    padding: 24px 16px 28px;
  }
`;

function normalizePath(path) {
  if (Array.isArray(path)) {
    return path.map(item => normalizePath(item));
  }
  return path.replace('//', '/');
}

class NestedRoutes extends React.Component {
  renderRoutes = ({routes, match}) => {
    let out = [];

    routes.forEach(route => {
      const key = route.path.toString();
      let path;

      if (Array.isArray(route.path)) {
        path = normalizePath(route.path.map(path => `${match.path}${path}`));
      } else {
        path = normalizePath(`${match.path}${route.path}`);
      }

      if (!(route.component || route.render) && route.routes) {
        out = out.concat(this.renderRoutes({routes: route.routes, match}));
        return;
      }

      if (route.redirect) {
        out.push(<Redirect exact={route.exact} key={key} from={path} to={route.redirect}/>);
      }

      if (route.guestOnly) {
        if (this.props.user.isAuthenticated) {
          out.push(<Redirect exact={route.exact} key={key} from={path} to={'/payments'}/>);
          return;
        }
      }

      if (route.requiresAuth) {
        if (!this.props.user.isAuthenticated) {
          out.push(<Redirect exact={route.exact} key={path} from={path} to={'/login'}/>);
          return;
      }
      }

      if (route.fillRequisites) {
        if (!this.props.user.profile) {
          out.push(<Redirect exact={route.exact} key={path} from={path} to={'/step/requisites'}/>);
          return;
        } else if (!this.props.user.profile.full_name) {
          out.push(<Redirect exact={route.exact} key={path} from={path} to={'/step/full-name'}/>);
          return;
        }
      }

      out.push(
        <Route
          key={key}
          path={path}
          exact={route.exact}
          render={route.component ? props => (
            <Suspense fallback={<PageBody/>}>
              <route.component {...props} routes={route.routes}/>
            </Suspense>
          ) : route.render}
        />
      );
    });

    return out;
  };

  render() {
    const {routes, match} = this.props;
    return (
      <Switch>
        {this.renderRoutes({routes, match})}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(NestedRoutes);
