import React from 'react';
import styled from 'styled-components';
import DashboardIcon from '../../assets/images/dashboard-blue.svg';
import {
  EuiIcon,
  EuiTitle,
  EuiText,
  EuiLoadingSpinner
} from '@elastic/eui';
import { useBreakpoints } from '../../utils/useBreakpoints';

const Wrapper = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled(EuiTitle)`
  margin: 32px 0 16px;
  @media ${({ theme }) => theme.media.phone} {
    margin: 16px 0 10px;
  }
`;

const Description = styled(EuiText)`
  color: ${({ theme }) => theme.colors.colorDarkShade};
  margin: 0 auto;
`;

const Icon = styled(EuiIcon)``;

const LoadingSpinner = styled(EuiLoadingSpinner)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
`;

const IconWrapper = styled.div`
  position: relative;
  ${({ $loading }) => !$loading} {
    ${Icon} {
      opacity: 0;
    }
    ${LoadingSpinner} {
      display: block;
    }
  }
`;

export const NotFound = (props) => {
  const { title, description, className, footer, icon, loading } = props
  const { isMobile } = useBreakpoints()
  return (
    <Wrapper className={className}>
      <IconWrapper
        $loading={loading}
      >
        <LoadingSpinner size='l' />
        <Icon type={icon || DashboardIcon} size='original' />
      </IconWrapper>
      <Title size={!isMobile ? 'm' : 'xs'}>
        <h3>{title}</h3>
      </Title>
      <Description size={!isMobile ? 'm' : 's'}>
        {description}
      </Description>
      {footer}
    </Wrapper>
  )
}

export default NotFound