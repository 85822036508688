import referralActionTypes from "./referralActionTypes";

const initialState = {
  offer: {
    loading: false,
    data: null,
  },
  offerDelete: {
    loading: false
  },
  inviteList: {
    loading: false,
    data: null,
  },
}

const reducers = {
  [referralActionTypes.REFERRAL_FETCH_OFFER_REQUEST]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        loading: true,
        data: null,
      }
    }
  },
  [referralActionTypes.REFERRAL_FETCH_OFFER_FULFILLED]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        loading: false,
        data: payload.offer,
      }
    }
  },
  [referralActionTypes.REFERRAL_FETCH_OFFER_FAIL]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        loading: false,
        data: null,
      }
    }
  },

  [referralActionTypes.REFERRAL_UPDATE_OFFER_REQUEST]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        loading: true,
      }
    }
  },
  [referralActionTypes.REFERRAL_UPDATE_OFFER_FULFILLED]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        loading: false,
        data: payload.offer,
      }
    }
  },
  [referralActionTypes.REFERRAL_UPDATE_OFFER_FAIL]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        loading: false,
        data: null,
      }
    }
  },

  [referralActionTypes.REFERRAL_CREATE_OFFER_REQUEST]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        loading: true,
        data: null,
      }
    }
  },
  [referralActionTypes.REFERRAL_CREATE_OFFER_FULFILLED]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        loading: false,
        data: payload.offer,
      }
    }
  },
  [referralActionTypes.REFERRAL_CREATE_OFFER_FAIL]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        loading: false,
        data: null,
      }
    }
  },

  [referralActionTypes.REFERRAL_DELETE_OFFER_REQUEST]: (state, payload) => {
    return {
      ...state,
      offerDelete: {
        loading: true
      }
    }
  },
  [referralActionTypes.REFERRAL_DELETE_OFFER_FULFILLED]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        data: null,
      },
      offerDelete: {
        loading: false
      }
    }
  },
  [referralActionTypes.REFERRAL_DELETE_OFFER_FAIL]: (state, payload) => {
    return {
      ...state,
      offer: {
        ...state.offer,
        data: null,
      },
      offerDelete: {
        loading: false
      }
    }
  },

  [referralActionTypes.REFERRAL_FETCH_LIST_REQUEST]: (state, payload) => {
    return {
      ...state,
      inviteList: {
        ...state.inviteList,
        loading: true,
        data: null,
      }
    }
  },
  [referralActionTypes.REFERRAL_FETCH_LIST_FULFILLED]: (state, payload) => {
    return {
      ...state,
      inviteList: {
        ...state.inviteList,
        loading: false,
        data: payload.inviteList,
      }
    }
  },
  [referralActionTypes.REFERRAL_FETCH_LIST_FAIL]: (state, payload) => {
    return {
      ...state,
      inviteList: {
        ...state.inviteList,
        loading: false,
        data: null,
      }
    }
  },

}

export default function referralReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
