const referralActionTypes = {
  REFERRAL_FETCH_OFFER_REQUEST: 'REFERRAL_FETCH_OFFER_REQUEST',
  REFERRAL_FETCH_OFFER_FULFILLED: 'REFERRAL_FETCH_OFFER_FULFILLED',
  REFERRAL_FETCH_OFFER_FAIL: 'REFERRAL_FETCH_OFFER_FAIL',

  REFERRAL_UPDATE_OFFER_REQUEST: 'REFERRAL_UPDATE_OFFER_REQUEST',
  REFERRAL_UPDATE_OFFER_FULFILLED: 'REFERRAL_UPDATE_OFFER_FULFILLED',
  REFERRAL_UPDATE_OFFER_FAIL: 'REFERRAL_UPDATE_OFFER_FAIL',

  REFERRAL_DELETE_OFFER_REQUEST: 'REFERRAL_DELETE_OFFER_REQUEST',
  REFERRAL_DELETE_OFFER_FULFILLED: 'REFERRAL_DELETE_OFFER_FULFILLED',
  REFERRAL_DELETE_OFFER_FAIL: 'REFERRAL_DELETE_OFFER_FAIL',

  REFERRAL_CREATE_OFFER_REQUEST: 'REFERRAL_CREATE_OFFER_REQUEST',
  REFERRAL_CREATE_OFFER_FULFILLED: 'REFERRAL_CREATE_OFFER_FULFILLED',
  REFERRAL_CREATE_OFFER_FAIL: 'REFERRAL_CREATE_OFFER_FAIL',

  REFERRAL_FETCH_LIST_REQUEST: 'REFERRAL_FETCH_LIST_REQUEST',
  REFERRAL_FETCH_LIST_FULFILLED: 'REFERRAL_FETCH_LIST_FULFILLED',
  REFERRAL_FETCH_LIST_FAIL: 'REFERRAL_FETCH_LIST_FAIL',
}

export default referralActionTypes
