import transactionsActionTypes from "./transactionsActionTypes";

const initialState = {
  list: [],
  paymentDetails: {},
  totalAmount: 0,
  totalSum: 0,
  counters: {
    not_completed: 0,
    pending: 0,
  },
  paymentStatus: [],
  reasonType: [],
  search: '',
  limit: 0,
  offset: 0,
  loading: false,
  accessDenied: false,
  confirm: {
    loading: false
  },
  report: {
    loading: false
  },
  paymentOrder: {
    loading: false
  },
  channelLimits: {
    result: [],
    loading: false
  }
}

const reducers = {
  [transactionsActionTypes.TRANSACTIONS_FETCH_REQUEST]: (state, payload) => {
    return {
      ...state,
      list: [],
      loading: true,
    }
  },
  [transactionsActionTypes.TRANSACTIONS_FETCH_FULFILLED]: (state, payload) => {
    return {
      ...state,
      list: payload.list,
      totalAmount: payload.totalAmount,
      totalSum: payload.totalSum,
      counters: payload.counters,
      loading: false,
      accessDenied: false
    }
  },
  [transactionsActionTypes.TRANSACTIONS_FETCH_FAIL]: (state, payload) => {
    return {
      ...state,
      loading: false,
      accessDenied: payload?.accessDenied
    }
  },
  [transactionsActionTypes.TRANSACTIONS_CONFIRM_REQUEST]: (state, payload) => {
    return {
      ...state,
      confirm: {
        ...state.confirm,
        loading: true,
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_CONFIRM_FAIL]: (state, payload) => {
    return {
      ...state,
      confirm: {
        ...state.confirm,
        loading: false,
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_CONFIRM_FULFILLED]: (state, payload) => {
    return {
      ...state,
      confirm: {
        ...state.confirm,
        loading: false,
      }
    }
  },

  [transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_REQUEST]: (state, payload) => {
    return {
      ...state,
      report: {
        ...state.report,
        loading: true,
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_FULFILLED]: (state, payload) => {
    return {
      ...state,
      report: {
        ...state.report,
        loading: false,
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_FAIL]: (state, payload) => {
    return {
      ...state,
      report: {
        ...state.report,
        loading: false,
      }
    }
  },

  [transactionsActionTypes.TRANSACTIONS_FETCH_COUNTERS_REQUEST]: (state, payload) => {
    return {
      ...state,
      counters: {
        not_completed: 0,
        pending: 0,
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_FETCH_COUNTERS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      counters: payload.counters
    }
  },
  [transactionsActionTypes.TRANSACTIONS_FETCH_COUNTERS_FAIL]: (state, payload) => {
    return {
      ...state,
      counters: {
        not_completed: 0,
        pending: 0,
      }
    }
  },

  [transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_REQUEST]: (state, payload) => {
    return {
      ...state,
      paymentOrder: {
        ...state.paymentOrder,
        loading: true,
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_FULFILLED]: (state, payload) => {
    return {
      ...state,
      paymentOrder: {
        ...state.paymentOrder,
        loading: false,
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_FAIL]: (state, payload) => {
    return {
      ...state,
      paymentOrder: {
        ...state.paymentOrder,
        loading: false,
      }
    }
  },
  [transactionsActionTypes.STORE_PAYMENT_DETAILS]: (state, payload) => {
    return {
      ...state,
      paymentDetails: {
        ...state.paymentDetails,
        ...payload
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_FETCH_CHANNEL_LIMITS_REQUEST]: (state, payload) => {
    return {
      ...state,
      channelLimits: {
        result: [],
        loading: true,
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_FETCH_CHANNEL_LIMITS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      channelLimits: {
        result: payload,
        loading: false,
      }
    }
  },
  [transactionsActionTypes.TRANSACTIONS_FETCH_CHANNEL_LIMITS_FAIL]: (state, payload) => {
    return {
      ...state,
      channelLimits: {
        result: [],
        loading: false,
      }
    }
  },
}

export default function transactionsReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
