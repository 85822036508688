import React from 'react'
import styled from "styled-components"

const Wrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: ${({$gap}) => !!$gap ? `${$gap}` : `5`}px;
  width: 100%;
  max-width: ${({$width}) => !!$width ? `${$width}px` : `100%`};
`;

const Line = styled.span`
  border-radius: ${({ $borderRadius }) => $borderRadius ? $borderRadius : 4}px;
  margin-bottom: 0;
  ${({$height}) => !!$height && `height: ${$height}px;`}
  &:last-child:not(:only-child) {
    width: 100%;
  }
`;

export const ContentLoading = (props) => {
  const {
    height,
    width,
    lines = 1,
    gap,
    borderRadius,
    className
  } = props

  const lineElements = []

  for (let i = 0; i < lines; i++) {
    lineElements.push(
      <Line
        key={i}
        className="euiLoadingContent__singleLine"
        $height={height}
        $borderRadius={borderRadius}
      >
        <span className="euiLoadingContent__singleLineBackground" />
      </Line>
    )
  }

  return (
    <Wrapper
      className={className}
      $gap={gap}
      $width={width}
    >
      {lineElements}
    </Wrapper>
  )
}