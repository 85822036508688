import {applyMiddleware, compose, createStore} from 'redux';
import {createBrowserHistory} from 'history';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import createRootReducer from './reducers';
import createReduxPromiseListener from 'redux-promise-listener'
import { toast } from "../utils/toast";
import * as appActions from "./app/appActions";
import { appErrors} from './app/model/appErrors'
import { routerMiddleware } from 'connected-react-router'

const reduxPromiseListener = createReduxPromiseListener()

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    if (err?.response?.status === 500 && err?.response?.data?.code === "TechnicalError") {
      store.dispatch(appActions.setError({error: appErrors.ENGINEERING_WORKS}))
      throw err
    }
    if (err?.response?.status === 401) {
      toast.add({
        title: 'Ошибка',
        text: 'Сессия истекла. Для продолжения работы авторизуйтесь повторно.',
        color: 'danger',
      })
      throw err
    }
    toast.add({
      title: 'Ошибка',
      text: 'При обработке запроса произошла ошибка.',
      color: 'danger',
    })

    store.dispatch(appActions.setError({error: appErrors.UNDEFINED_ERROR}))
    
    throw err
  }
});
const history = createBrowserHistory();

const devPlugins = [];
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  devPlugins.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(routerMiddleware(history), sagaMiddleware, reduxPromiseListener.middleware),
    ...devPlugins
  )
);

sagaMiddleware.run(rootSaga);

const promiseListener = reduxPromiseListener

export {history, store, promiseListener};
