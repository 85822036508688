import driversActionTypes from "./driversActionTypes";

const initialState = {
  driversList: {
    loading: false,
    data: null,
    accessDenied: false,
  },
  driver: {
    loading: false,
    data: null,
    accessDenied: false,
    driverNotFound: false
  },
  driverTransactions: {
    loading: false,
    data: null
  },
  driverPaymentSettings: {
    loading: false,
    data: null,
    driverNotInMozen: false
  },
  driverDeleteContract: {
    loading: false
  },
  driverUpdateContract: {
    loading: false
  },
  noContract: {
    loading: false
  },
  uploadedContracts: {
    loading: false,
    data: null
  },
  commitContracts: {
    loading: false
  },
  deleteAuthSessions: {
    loading: false
  }
}

const reducers = {
  [driversActionTypes.DRIVERS_FETCH_REQUEST]: (state, payload) => {
    return {
      ...state,
      driversList: {
        ...state.driversList,
        loading: true,
        data: null,
      }
    }
  },
  [driversActionTypes.DRIVERS_FETCH_FULFILLED]: (state, payload) => {
    return {
      ...state,
      driversList: {
        ...state.driversList,
        loading: false,
        data: payload.driversList,
        accessDenied: false
      }
    }
  },
  [driversActionTypes.DRIVERS_FETCH_FAIL]: (state, payload) => {
    return {
      ...state,
      driversList: {
        ...state.driversList,
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },

  [driversActionTypes.DRIVER_FETCH_REQUEST]: (state, payload) => {
    return {
      ...state,
      driver: {
        ...state.driver,
        loading: true,
        driverNotFound: false,
        data: null,
      }
    }
  },
  [driversActionTypes.DRIVER_FETCH_FULFILLED]: (state, payload) => {
    return {
      ...state,
      driver: {
        ...state.driver,
        loading: false,
        data: payload.driver,
        driverNotFound: false,
        accessDenied: false
      }
    }
  },
  [driversActionTypes.DRIVER_FETCH_FAIL]: (state, payload) => {
    return {
      ...state,
      driver: {
        ...state.driver,
        loading: false,
        data: null,
        driverNotFound: payload?.driverNotFound,
        accessDenied: payload?.accessDenied
      }
    }
  },

  [driversActionTypes.DRIVER_FETCH_TRANSACTIONS_REQUEST]: (state, payload) => {
    return {
      ...state,
      driverTransactions: {
        ...state.driverTransactions,
        loading: true,
        data: null,
      }
    }
  },
  [driversActionTypes.DRIVER_FETCH_TRANSACTIONS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      driverTransactions: {
        ...state.driverTransactions,
        loading: false,
        data: payload.driverTransactions,
      }
    }
  },
  [driversActionTypes.DRIVER_FETCH_TRANSACTIONS_FAIL]: (state, payload) => {
    return {
      ...state,
      driverTransactions: {
        ...state.driverTransactions,
        loading: false,
        data: null,
      }
    }
  },

  [driversActionTypes.DRIVER_FETCH_PAYMENT_SETTINGS_REQUEST]: (state, payload) => {
    return {
      ...state,
      driverPaymentSettings: {
        ...state.driverPaymentSettings,
        loading: true,
        data: null,
      }
    }
  },
  [driversActionTypes.DRIVER_FETCH_PAYMENT_SETTINGS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      driverPaymentSettings: {
        ...state.driverPaymentSettings,
        loading: false,
        data: payload.driverPaymentSettings,
      }
    }
  },
  [driversActionTypes.DRIVER_FETCH_PAYMENT_SETTINGS_FAIL]: (state, payload) => {
    return {
      ...state,
      driverPaymentSettings: {
        ...state.driverPaymentSettings,
        loading: false,
        driverNotInMozen: payload?.driverNotInMozen
      },
    }
  },

 [driversActionTypes.DRIVER_DELETE_CONTRACT_REQUEST]: (state, payload) => {
    return {
      ...state,
      driverDeleteContract: {
        ...state.driverDeleteContract,
        loading: true,
      }
    }
  },
  [driversActionTypes.DRIVER_DELETE_CONTRACT_FULFILLED]: (state, payload) => {
    return {
      ...state,
      driverDeleteContract: {
        ...state.driverDeleteContract,
        loading: false,
      }
    }
  },
  [driversActionTypes.DRIVER_DELETE_CONTRACT_FAIL]: (state, payload) => {
    return {
      ...state,
      driverDeleteContract: {
        ...state.driverDeleteContract,
        loading: false,
      }
    }
  },

  [driversActionTypes.DRIVER_UPDATE_CONTRACT_REQUEST]: (state, payload) => {
    return {
      ...state,
      driverUpdateContract: {
        ...state.driverUpdateContract,
        loading: true,
      }
    }
  },
  [driversActionTypes.DRIVER_UPDATE_CONTRACT_FULFILLED]: (state, payload) => {
    return {
      ...state,
      driverUpdateContract: {
        ...state.driverUpdateContract,
        loading: false,
      }
    }
  },
  [driversActionTypes.DRIVER_UPDATE_CONTRACT_FAIL]: (state, payload) => {
    return {
      ...state,
      driverUpdateContract: {
        ...state.driverUpdateContract,
        loading: false,
      }
    }
  },

  [driversActionTypes.DRIVERS_DOWNLOAD_NO_CONTRACT_REQUEST]: (state, payload) => {
    return {
      ...state,
      noContract: {
        ...state.noContract,
        loading: true,
      }
    }
  },
  [driversActionTypes.DRIVERS_DOWNLOAD_NO_CONTRACT_FULFILLED]: (state, payload) => {
    return {
      ...state,
      noContract: {
        ...state.noContract,
        loading: false,
      }
    }
  },
  [driversActionTypes.DRIVERS_DOWNLOAD_NO_CONTRACT_FAIL]: (state, payload) => {
    return {
      ...state,
      noContract: {
        ...state.noContract,
        loading: false,
      }
    }
  },

  [driversActionTypes.DRIVERS_UPLOAD_CONTRACTS_REQUEST]: (state, payload) => {
    return {
      ...state,
      uploadedContracts: {
        ...state.uploadedContracts,
        loading: true,
        data: null,
      }
    }
  },
  [driversActionTypes.DRIVERS_UPLOAD_CONTRACTS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      uploadedContracts: {
        ...state.uploadedContracts,
        loading: false,
        data: payload.uploadedContracts,
      }
    }
  },
  [driversActionTypes.DRIVERS_UPLOAD_CONTRACTS_FAIL]: (state, payload) => {
    return {
      ...state,
      uploadedContracts: {
        ...state.uploadedContracts,
        loading: false,
      }
    }
  },

  [driversActionTypes.DRIVERS_RESET_UPLOADED_CONTRACTS]: (state, payload) => {
    return {
      ...state,
      uploadedContracts: {
        ...state.uploadedContracts,
        data: null,
      }
    }
  },

  [driversActionTypes.DRIVERS_COMMIT_CONTRACTS_REQUEST]: (state, payload) => {
    return {
      ...state,
      commitContracts: {
        ...state.commitContracts,
        loading: true,
      }
    }
  },
  [driversActionTypes.DRIVERS_COMMIT_CONTRACTS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      commitContracts: {
        ...state.commitContracts,
        loading: false,
      }
    }
  },
  [driversActionTypes.DRIVERS_COMMIT_CONTRACTS_FAIL]: (state, payload) => {
    return {
      ...state,
      commitContracts: {
        ...state.commitContracts,
        loading: false,
      }
    }
  },

  [driversActionTypes.DRIVERS_DELETE_AUTH_SESSIONS_REQUEST]: (state, payload) => {
    return {
      ...state,
      deleteAuthSessions: {
        ...state.deleteAuthSessions,
        loading: true,
      }
    }
  },
  [driversActionTypes.DRIVERS_DELETE_AUTH_SESSIONS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      deleteAuthSessions: {
        ...state.deleteAuthSessions,
        loading: false,
      }
    }
  },
  [driversActionTypes.DRIVERS_DELETE_AUTH_SESSIONS_FAIL]: (state, payload) => {
    return {
      ...state,
      deleteAuthSessions: {
        ...state.deleteAuthSessions,
        loading: false,
      }
    }
  },
}

export default function driversReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
