export const transactionTypes = {
  "topup": {
    translate: 'Пополнение',
    id: 'topup'
  },
  "withdraw": {
    translate: 'Вывод средств',
    id: 'withdraw'
  },
  "debit": {
    translate: 'Доходные',
    id: 'debit'
  },
  "credit": {
    translate: 'Расходные',
    id: 'credit'
  },
}
