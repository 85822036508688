import { call, all, put, select } from 'redux-saga/effects'
import { takeLatest } from '@redux-saga/core/effects'
import driversActionTypes from "./driversActionTypes";
import { driverTabFilter } from './model/driverTabFilter';
import { transactionReasons } from "../transactions/model/transactionReasons";
import { transactionPaymentStatus } from "../transactions/model/transactionPaymentStatus";
import { transactionTypes } from "../transactions/model/transactionTypes";
import { network } from "../network";
import { toast } from 'utils/toast';

function* fetchDrivers(action) {
  const {
    limit,
    tab,
    offset,
    park_accounts,
    statuses,
    search,
    sort_column,
    sort_direction
  } = action.payload

  const workStatusFilters = driverTabFilter[tab]?.work_statuses.map(({ id }) => id)
  const statusFilters = tab ? statuses?.reduce((array, status) => {
    driverTabFilter[tab]?.statuses?.forEach(({ id }) => id === status && array.push(id))
    return array
  }, []) : statuses

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/drivers`,
      method: 'GET',
      params: {
        search,
        limit,
        offset,
        park_accounts,
        sort_column,
        sort_direction,
        statuses: statusFilters,
        work_statuses: workStatusFilters
      }
    })

    yield put({
      type: driversActionTypes.DRIVERS_FETCH_FULFILLED,
      payload: {
        driversList: response.data,
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVERS_FETCH_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: driversActionTypes.DRIVERS_FETCH_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: driversActionTypes.DRIVERS_FETCH_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchDriver(action) {
  const { driver_id } = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/${driver_id}`,
      method: 'GET',
      data: { driver_id }
    })

    yield put({
      type: driversActionTypes.DRIVER_FETCH_FULFILLED,
      payload: {
        driver: response.data
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 404 && err.response.data.code === "DriverNotFound") {
      yield put({
        type: driversActionTypes.DRIVER_FETCH_FAIL,
        payload: {driverNotFound: true},
      })
      return
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVER_FETCH_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: driversActionTypes.DRIVER_FETCH_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    throw err
  }
}

function* fetchDriverTransactions(action) {
  const { limit, driver_id, offset, filters } = action.payload

  const statusFilters = []
  const reasonFilters = []
  const typeFilters = []
  Object.entries(filters).forEach(([filter, value]) => {
    if (!value) {
      return
    }
    if (transactionReasons[filter]) {
      reasonFilters.push(filter)
    }
    if (transactionPaymentStatus[filter]) {
      statusFilters.push(filter)
    }
    if (transactionTypes[filter]) {
      typeFilters.push(filter)
    }
  })
  
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/${driver_id}/transactions`,
      method: 'GET',
      params: {
        driver_id,
        limit,
        offset,
        tx_reasons: reasonFilters,
        tx_statuses: statusFilters,
        tx_types: typeFilters,
      }
    })

    yield put({
      type: driversActionTypes.DRIVER_FETCH_TRANSACTIONS_FULFILLED,
      payload: {
        driverTransactions: response.data
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVER_FETCH_TRANSACTIONS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "DriverNotInMozen") {
      yield put({
        type: driversActionTypes.DRIVER_FETCH_TRANSACTIONS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchDriverPaymentSettings(action) {
  const { driver_id } = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/${driver_id}/payment-settings`,
      method: 'GET',
    });

    yield put({
      type: driversActionTypes.DRIVER_FETCH_PAYMENT_SETTINGS_FULFILLED,
      payload: {
        driverPaymentSettings: response.data
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVER_FETCH_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "DriverNotInMozen") {
      yield put({
        type: driversActionTypes.DRIVER_FETCH_PAYMENT_SETTINGS_FAIL,
        payload: {driverNotInMozen: true},
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "DriverNotFound") {
      yield put({
        type: driversActionTypes.DRIVER_FETCH_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* updateDriverPaymentSettings(action) {
  const {
    auto_withdraw,
    day_withdraw_limit,
    fair_rides,
    free_withdraws_count,
    fuel_card_auto_topup,
    fuel_card_max_topup_amount,
    fuel_card_min_topup_amount,
    fuel_card_topup_restricted,
    id,
    is_group,
    is_park_default,
    max_withdraw_amount,
    min_balance,
    min_commission,
    min_withdraw_amount,
    name,
    non_resident_min_commission,
    non_resident_withdraw_commission,
    week_withdraw_limit,
    withdraw_commission,
    withdraw_restricted,
    withdraw_transactions_for_auto_withdraw,
    driver_id
  } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/${driver_id}/payment-settings`,
      method: 'PATCH',
      data: {
        auto_withdraw,
        day_withdraw_limit,
        fair_rides,
        free_withdraws_count,
        fuel_card_auto_topup,
        fuel_card_max_topup_amount,
        fuel_card_min_topup_amount,
        fuel_card_topup_restricted,
        id,
        is_group,
        is_park_default,
        max_withdraw_amount,
        min_balance,
        min_commission,
        min_withdraw_amount,
        name,
        non_resident_min_commission,
        non_resident_withdraw_commission,
        week_withdraw_limit,
        withdraw_commission,
        withdraw_restricted,
        withdraw_transactions_for_auto_withdraw,
      }
    })

    yield put({
      type: driversActionTypes.DRIVER_UPDATE_PAYMENT_SETTINGS_FULFILLED,
      payload: {}
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось изменить',
        text: 'Пожалуйста, повторите попытку позжее',
        color: 'danger',
      })
    }
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVER_UPDATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "PaymentSettingsCannotBeUpdated") {
      yield put({
        type: driversActionTypes.DRIVER_UPDATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: driversActionTypes.DRIVER_UPDATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: driversActionTypes.DRIVER_UPDATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "ParkNotFound") {
      yield put({
        type: driversActionTypes.DRIVER_UPDATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* createDriverPaymentSettings(action) {
  const {
    auto_withdraw,
    day_withdraw_limit,
    fair_rides,
    free_withdraws_count,
    fuel_card_auto_topup,
    fuel_card_max_topup_amount,
    fuel_card_min_topup_amount,
    fuel_card_topup_restricted,
    id,
    is_group,
    is_park_default,
    max_withdraw_amount,
    min_balance,
    min_commission,
    min_withdraw_amount,
    name,
    non_resident_min_commission,
    non_resident_withdraw_commission,
    week_withdraw_limit,
    withdraw_commission,
    withdraw_restricted,
    withdraw_transactions_for_auto_withdraw,
    driver_id
  } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/${driver_id}/payment-settings`,
      method: 'POST',
      data: {
        auto_withdraw,
        day_withdraw_limit,
        fair_rides,
        free_withdraws_count,
        fuel_card_auto_topup,
        fuel_card_max_topup_amount,
        fuel_card_min_topup_amount,
        fuel_card_topup_restricted,
        id,
        is_group,
        is_park_default,
        max_withdraw_amount,
        min_balance,
        min_commission,
        min_withdraw_amount,
        name,
        non_resident_min_commission,
        non_resident_withdraw_commission,
        week_withdraw_limit,
        withdraw_commission,
        withdraw_restricted,
        withdraw_transactions_for_auto_withdraw
      }
    })

    yield put({
      type: driversActionTypes.DRIVER_CREATE_PAYMENT_SETTINGS_FULFILLED,
      payload: {}
    })
  } catch (e) {
    throw e
  }
}

function* updateDriverContract(action) {
  const { data, driver_id } = action.payload
  const state = yield select()
  const { contract } = state.drivers.driver.data

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/${driver_id}/contracts`,
      method: 'PATCH',
      data: {
        ...data,
        second_name: data.second_name || null
      }
    })

    yield put({
      type: driversActionTypes.DRIVER_UPDATE_CONTRACT_FULFILLED,
      payload: {}
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVER_UPDATE_CONTRACT_FAIL,
        payload: err.response.data,
      })
      toast.add({
        title: 'Ошибка',
        text: contract ? 'Не удалось изменить данные, повторите попытку позже' : 'Не удалось добавить договор, повторите попытку позже',
        color: 'danger',
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: driversActionTypes.DRIVER_UPDATE_CONTRACT_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* deleteDriverContract(action) {
  const { driver_id } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/${driver_id}/contracts`,
      method: 'DELETE',
    })

    yield put({
      type: driversActionTypes.DRIVER_DELETE_CONTRACT_FULFILLED,
      payload: {}
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Ошибка',
        text: 'Не удалось удалить договор, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVER_DELETE_CONTRACT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "DriverNotFound") {
      yield put({
        type: driversActionTypes.DRIVER_DELETE_CONTRACT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: driversActionTypes.DRIVER_DELETE_CONTRACT_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* downloadNoContractDrivers(action) {
  const { aggregators } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/contracts/no-contract`,
      method: 'POST',
      responseType: 'blob',
      data: {
        aggregators
      }
    })

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] })
    )
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'drivers.xlsx'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) {
        const newFileName = fileNameMatch[1]
        fileName = newFileName
      }
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
    yield put({
      type: driversActionTypes.DRIVERS_DOWNLOAD_NO_CONTRACT_FULFILLED,
      payload: {}
    })

    toast.add({
      title: 'Успешно',
      text: 'Внесите данные водителей в загруженную таблицу',
      color: 'primary',
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось скачать',
        text: 'Произошла ошибка. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }
    
    if (err.response && err.response.status === 500) {
      yield put({
        type: driversActionTypes.DRIVERS_DOWNLOAD_NO_CONTRACT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403) {
      yield put({
        type: driversActionTypes.DRIVERS_DOWNLOAD_NO_CONTRACT_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400) {
      yield put({
        type: driversActionTypes.DRIVERS_DOWNLOAD_NO_CONTRACT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* uploadContracts(action) {
  const { file } = action.payload

  const formData = new FormData()
  const blob = new Blob([file], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  formData.append('file', blob)
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/contracts/upload`,
      method: 'POST',
      data: formData
    })

    yield put({
      type: driversActionTypes.DRIVERS_UPLOAD_CONTRACTS_FULFILLED,
      payload: {
        uploadedContracts: response.data
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVERS_UPLOAD_CONTRACTS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: driversActionTypes.DRIVERS_UPLOAD_CONTRACTS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: driversActionTypes.DRIVERS_UPLOAD_CONTRACTS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* commitContracts(action) {
  const { contracts } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/drivers/contracts/commit`,
      method: 'POST',
      data: contracts
    })

    yield put({
      type: driversActionTypes.DRIVERS_COMMIT_CONTRACTS_FULFILLED,
      payload: {}
    })

    toast.add({
      title: 'Данные сохранены',
      text: 'Данные о договорах водителей сохранены в базе данных',
      color: 'success',
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось отправить',
        text: 'Произошла ошибка. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVERS_COMMIT_CONTRACTS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: driversActionTypes.DRIVERS_COMMIT_CONTRACTS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: driversActionTypes.DRIVERS_COMMIT_CONTRACTS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* deleteAuthSessions(action) {
  const { driver_id } = action.payload

  try {
    yield call(network.appAxios, {
      url: `/erp/1/drivers/${driver_id}/sessions`,
      method: 'DELETE',
    })

    yield put({
      type: driversActionTypes.DRIVERS_DELETE_AUTH_SESSIONS_FULFILLED,
      payload: {}
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Ошибка',
        text: 'Не удалось удалить сессии водителя, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: driversActionTypes.DRIVERS_DELETE_AUTH_SESSIONS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "DriverNotFound") {
      yield put({
        type: driversActionTypes.DRIVERS_DELETE_AUTH_SESSIONS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: driversActionTypes.DRIVERS_DELETE_AUTH_SESSIONS_FAIL,
        payload: err.response.data,
      })
      return
    }
    throw err
  }
}


export default function* driversSagas() {
  yield all([
    yield takeLatest(driversActionTypes.DRIVERS_FETCH_REQUEST, fetchDrivers),
    yield takeLatest(driversActionTypes.DRIVER_FETCH_REQUEST, fetchDriver),
    yield takeLatest(driversActionTypes.DRIVER_FETCH_TRANSACTIONS_REQUEST, fetchDriverTransactions),
    yield takeLatest(driversActionTypes.DRIVER_FETCH_PAYMENT_SETTINGS_REQUEST, fetchDriverPaymentSettings),
    yield takeLatest(driversActionTypes.DRIVER_UPDATE_PAYMENT_SETTINGS_REQUEST, updateDriverPaymentSettings),
    yield takeLatest(driversActionTypes.DRIVER_CREATE_PAYMENT_SETTINGS_REQUEST, createDriverPaymentSettings),
    yield takeLatest(driversActionTypes.DRIVER_UPDATE_CONTRACT_REQUEST, updateDriverContract),
    yield takeLatest(driversActionTypes.DRIVER_DELETE_CONTRACT_REQUEST, deleteDriverContract),
    yield takeLatest(driversActionTypes.DRIVERS_DOWNLOAD_NO_CONTRACT_REQUEST, downloadNoContractDrivers),
    yield takeLatest(driversActionTypes.DRIVERS_UPLOAD_CONTRACTS_REQUEST, uploadContracts),
    yield takeLatest(driversActionTypes.DRIVERS_COMMIT_CONTRACTS_REQUEST, commitContracts),
    yield takeLatest(driversActionTypes.DRIVERS_DELETE_AUTH_SESSIONS_REQUEST, deleteAuthSessions),
  ])
}
