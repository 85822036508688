import React from 'react';
import styled from 'styled-components';
import closeIcon from 'assets/images/close-bold.svg'
import { Flyout} from '../Flyout/Flyout';

const Wrapper = styled(Flyout)`
  box-shadow: none;
  min-width: auto;
  max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : 'auto'} !important;
  width: calc(100% - 56px);
  button.closeButtonFlyout.euiButtonIcon.euiButtonIcon--text {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
    color: transparent !important;
    transform: translateX(calc(${({ side }) => side === 'left' ? '100%' : '-100%'}  - 4px)) !important;
    &:not(.euiButtonIcon--empty) {
      background-color: ${({ theme }) => theme.colors.colorMediumShade};
      color: ${({ theme }) => theme.colors.colorEmptyShade};
      border-radius: 100%;
      top: 20px;
      left: ${({ side }) => side === 'left' ? 'auto' : '-15px'};
      right: ${({ side }) => side === 'left' ? '-15px' : 'auto'};
    }
    &:focus-visible , &:focus-within, &:focus, &:active {
      outline: none;
    }
  }
`;

export const RoundedCloseFlyout = (props) => {
  const { children, className, closeButtonPosition, ...rest } = props
  return (
    <Wrapper
      closeButtonPosition={closeButtonPosition || 'outside'}
      className={className}
      closeButtonProps={{
        className: 'closeButtonFlyout',
        iconType: closeIcon,
        size: 's'
      }}
      {...rest}
    >
      {children}
    </Wrapper>
  )
}