export const transactionPaymentStatus = {
  "authorized": {
    id: 'authorized',
    label: 'label',
    translate: 'Разрешен',
  },
  "pending": {
    id: 'pending',
    label: 'label',
    translate: 'Ожидает подтверждения',
  },
  "acknowledged": {
    id: 'acknowledged',
    label: 'label',
    translate: 'В очереди',
  },
  "processing": {
    id: 'processing',
    label: 'Обработка банком',
    translate: 'Обработка банком',
  },
  "succeeded": {
    id: 'succeeded',
    label: 'Успешно завершен',
    translate: 'Завершен',
  },
  "declined": {
    id: 'declined',
    label: 'Отказано в выводе',
    translate: 'Отклонен',
  },
  "failed": {
    id: 'failed',
    label: 'Ошибка платежа',
    translate: 'Ошибка',
  },
  "not_completed": {
    id: 'not_completed',
    label: 'label',
    translate: 'Не завершен',
  },
}
