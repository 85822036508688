import React from 'react';
import styled from 'styled-components';
import {
  EuiCard,
} from '@elastic/eui';

const Wrapper = styled(EuiCard)`
  background-color: ${({ theme }) => theme.colors.colorEmptyShade};
  box-shadow: ${({ theme }) => theme.boxShadows.bottomLight};
  padding: 20px 16px;
  .euiCard__children {
    margin-top: 16px;
  }
  .euiCard__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: inherit;
    margin-bottom: 20px;
  }
  .euiCard__top + .euiCard__content {
    margin-top: 0;
  }
  .euiTitle {
    font-size: 19px;
  }
  .euiCard__top .euiCard__icon {
    margin-top: 0;
  }
  
  @media ${({ theme }) => theme.media.phone} {
    .euiCard__top {
      flex-direction: row-reverse;
      margin-bottom: 12px;
    }
  }
`;


export const BaseCard = (props) => {
  const { className, title, body, footer, topSecondary, topBase } = props

  return (
    <Wrapper
      className={className}
      icon={(topSecondary || topBase) ? (
        <>
          {topBase && topBase}
          {topSecondary && topSecondary}
        </>
      ) : null}
      title={title}
      textAlign='left'
      paddingSize='none'
      display
      children={body}
      footer={footer}
    />
  )
}