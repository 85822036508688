import { call, all, put } from 'redux-saga/effects'
import { takeLatest } from '@redux-saga/core/effects'
import transactionsActionTypes from "./transactionsActionTypes";
import { network } from "../network";
import { transactionTabFilters } from "./model/transactionTabFilters";
import { transactionReasons } from "./model/transactionReasons";
import { transactionPaymentStatus } from "./model/transactionPaymentStatus";
import { transactionsActions } from "./transactionsActions";
import { toast } from 'utils/toast';
import {history} from "../store";

function* transactionsFetch(action) {
  const {payload} = action
  const {tab, dateFilter, filters, limit, offset, search} = payload

  const paymentStatusFilters = []
  const reasonTypeFilters = []
  Object.entries(filters).forEach(([filter, value]) => {
    if (!value) {
      return
    }
    if (transactionReasons[filter]) {
      reasonTypeFilters.push(filter)
    }
    if (transactionPaymentStatus[filter]) {
      paymentStatusFilters.push(filter)
    }
  })
  
  const payment_status = Array.from(new Set([
    ...(paymentStatusFilters.length ? paymentStatusFilters : transactionTabFilters[tab].emptyPaymentStatus.map(status => status.id)),
    ...transactionTabFilters[tab].payment_status.map(status => status.id),
  ]))
  
  const reason_type = Array.from(new Set([
    ...(reasonTypeFilters.length ? reasonTypeFilters : transactionTabFilters[tab].emptyReasonType.map(reason => reason.id)),
    ...transactionTabFilters[tab].reason_type.map(reason => reason.id),
  ]))
  const params = {}

  if (dateFilter) {
    params.date_from = dateFilter.date_from
    params.date_to = dateFilter.date_to
  }

  try {
    const [transactionsResponse, countersResponse] = yield all([
      call(network.appAxios, {
        url: `/erp/2/park/transactions`,
        method: 'GET',
        params: {
          search,
          limit,
          offset,
          payment_status,
          reason_type,
          ...params,
        }
      }),
      call(network.appAxios, {
        url: `/erp/1/park/transactions/counters`,
        method: 'GET',
      }),
    ])
    const {total_amount, transactions, total_sum} = transactionsResponse.data

    yield put({
      type: transactionsActionTypes.TRANSACTIONS_FETCH_FULFILLED,
      payload: {
        totalAmount: total_amount,
        totalSum: total_sum,
        list: transactions,
        counters: countersResponse.data,
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_FETCH_FAIL,
        payload: {}
      })
      toast.add({
        title: 'Ошибка',
        text: 'При обработке запроса произошла ошибка.',
        color: 'danger',
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_FETCH_FAIL,
        payload: {accessDenied: true},
      })
      return 
    }

    throw err
  }
}

function* fetchCounters(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/transactions/counters`,
      method: 'GET',
    })

    yield put({
      type: transactionsActionTypes.TRANSACTIONS_FETCH_COUNTERS_FULFILLED,
      payload: {
        counters: {
          not_completed: response.data.not_completed,
          pending: response.data.pending,
        }
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_FETCH_COUNTERS_FAIL,
        payload: {},
      })
      return 
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_FETCH_COUNTERS_FAIL,
        payload: {},
      })
      return
    }


    throw err
  }
}

function* confirm(action) {
  const {payload} = action
  const {confirmed, ids, updateData, status} = payload

  const transactionsLength = ids.length

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/transactions/confirm`,
      method: 'POST',
      data: {
        transaction_ids: ids,
        confirmed,
      }
    })

    yield put({
      type: transactionsActionTypes.TRANSACTIONS_CONFIRM_FULFILLED,
      payload: response.data,
    })
    if (status === transactionPaymentStatus.not_completed.id && !confirmed) {
      toast.add({
        title: transactionsLength > 1 ? 'Платежи возвращены' : 'Платеж возвращен',
        text: 'Деньги будут возвращены водителю на баланс таксометра.',
        color: 'primary',
      })
    }
    if (status === transactionPaymentStatus.not_completed.id && confirmed) {
      toast.add({
        title: transactionsLength > 1 ? 'Платежи завершены' : 'Платеж завершен',
        text: 'Деньги будут зачислены на карты водителей.',
        color: 'primary',
      })
    }
    if (status === transactionPaymentStatus.pending.id && confirmed) {
      toast.add({
        title: transactionsLength > 1 ? 'Платежи подтверждены' : 'Платеж подтвержден',
        text: 'Информация о статусе операций доступна в деталях транзакции.',
        color: 'primary',
      })
    }
    if (status === transactionPaymentStatus.pending.id && !confirmed) {
      toast.add({
        title: transactionsLength > 1 ? 'Платежи отклонены' : 'Платеж отклонен',
        text: 'Деньги вернутся на баланс таксометра.',
        color: 'primary',
      })
    }
    yield put(transactionsActions.fetch(updateData))
  } catch (err) {
    const alertError = () => {
      if (status === transactionPaymentStatus.not_completed.id && !confirmed) {
        toast.add({
          title: 'Возврат не выполнен',
          text: 'Произошла ошибка, статус транзакции не изменен.',
          color: 'danger',
        })
      }
      if (status === transactionPaymentStatus.not_completed.id && confirmed) {
        toast.add({
          title: transactionsLength > 1 ? 'Платежи не завершены' : 'Платеж не завершён',
          text: 'Статус операции не изменен.',
          color: 'danger',
        })
      }
      if (status === transactionPaymentStatus.pending.id && confirmed) {
        toast.add({
          title: transactionsLength > 1 ? 'Платежи не подтверждены' : 'Платеж не подтвержден',
          text: 'Произошла ошибка, повторите попытку позже',
          color: 'danger',
        })
      }
      if (status === transactionPaymentStatus.pending.id && !confirmed) {
        toast.add({
          title: transactionsLength > 1 ? 'Не удалось отклонить платежи' : 'Не удалось отклонить платеж',
          text: 'Статус операции не изменен.',
          color: 'danger',
        })
      }
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CONFIRM_FAIL,
        payload: {},
      })
      alertError()
      return 
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CONFIRM_FAIL,
        payload: {},
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "CannotPerformTransactionCommand") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CONFIRM_FAIL,
        payload: {},
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "TransactionsNotFound") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CONFIRM_FAIL,
        payload: {},
      })
      alertError()
      return 
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CONFIRM_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "AggregatorsInternalError") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CONFIRM_FAIL,
        payload: err.response.data,
      })

      if (transactionsLength === 1 && err.response.data.message?.includes("Driver not found")) {
        history.push('/drivers/driver/not-found');
        return;
      }

      alertError();
      return
    }
    
    throw err
  }
}


function* createReport(action) {
  //todo: remove, duplicated
  // const {date_start, date_end, report_type, aggregators} = action.payload
  // try {
  //   const response = yield call(network.appAxios, {
  //     url: `/erp/1/report`,
  //     method: 'POST',
  //     data: {
  //       date_start,
  //       date_end,
  //       report_type,
  //       aggregators,
  //     }
  //   })    

  //   yield put({
  //     type: transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_FULFILLED,
  //     payload: {},
  //   })
  // } catch (err) {
  //   const alertError = () => {
  //     toast.add({
  //       title: report_type === '1c' ? 'Отчет для 1С не сформирован' : 'Отчет для для сверки платежей не сформирован',
  //       text: 'Не удалось сформировать отчет. Попробуйте позже',
  //       color: 'danger',
  //     })
  //   }

  //   if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
  //     yield put({
  //       type: transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_FAIL,
  //       payload: {},
  //     })
  //     alertError()
  //     return 
  //   }

  //   if (err.response && err.response.status === 403 && err.response.data.code === "PermissionDenied") {
  //     yield put({
  //       type: transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_FAIL,
  //       payload: {},
  //     })
  //     toast.add({
  //       title: 'Ошибка',
  //       text: err.response.data.message,
  //       color: 'danger',
  //     })
  //     return
  //   }

  //   if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
  //     yield put({
  //       type: transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_FAIL,
  //       payload: {},
  //     })
  //     alertError()
  //     return 
  //   }

  //   if (err.response && err.response.status === 409 && err.response.data.code === "DayReportQtyLimitError") {
  //     yield put({
  //       type: transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_FAIL,
  //       payload: {},
  //     })
  //     alertError()
  //     return 
  //   }

  //   throw err
  // }
}

function* createPaymentOrder(action) {
  const {transaction_ids, file_format, xml_payment_order_data} = action.payload
  const transactionsLength = transaction_ids.length
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/transactions/payment-order`,
      method: 'POST',
      data: {
        transaction_ids,
        file_format,
        xml_payment_order_data
      }
    })    

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] })
    )
    const link = document.createElement('a')
    link.href = url
    let fileName = 'paymentOrder.txt'
    const fileNameMatch = response.headers['content-disposition']?.match(/filename="(.+)"/);
    console.log('download order', response.headers, fileNameMatch);
    if (fileNameMatch && fileNameMatch.length === 2) {
      fileName = fileNameMatch[1]
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)

    yield put({
      type: transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_FULFILLED,
      payload: {},
    })

    toast.add({
      title: 'Успешно',
      text: transactionsLength > 1 ? 'Платежные поручения готовы.' : 'Платежное поручение готово.',
      color: 'success',
    })
  } catch (err) {
      const alertError = () => {
        toast.add({
          title: transactionsLength > 1 ? 'Платежные поручения не сформированы' : 'Платежное поручение не сформировано',
          text: transactionsLength > 1 ? 'Не удалось подготовить поручения для выплат. Попробуйте позже' : 'Не удалось подготовить поручение для выплат. Попробуйте позже',
          color: 'danger',
        })
      }

     if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_FAIL,
        payload: {},
      })
      alertError()
      return 
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_FAIL,
        payload: {},
      })
      alertError()
      return 
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "PaymentDetailsNotFound") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_FAIL,
        payload: {},
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchChannelLimits(action) {

  try {
    const queryString = action.payload.map(channel => `channels[]=${channel}`).join('&');
    const url = `/erp/1/channels/limits?${queryString}`;

    const response = yield call(network.appAxios, {
      url: url,
      method: 'GET'
    });
    yield put({
      type: transactionsActionTypes.TRANSACTIONS_FETCH_CHANNEL_LIMITS_FULFILLED,
      payload: response.data,
    });
  } catch (err) {
    const handleError = (title, text) => {
      toast.add({
        title: title,
        text: text,
        color: 'danger',
      });
    };

    if (err.response) {
      const { status } = err.response;

      yield put({
        type: transactionsActionTypes.TRANSACTIONS_FETCH_CHANNEL_LIMITS_FAIL,
        payload: {},
      });

      if (status === 500) {
        handleError('Ошибка сервера', 'Произошла ошибка сервера. Попробуйте позже');
      } else if (status === 400) {
        handleError('Неверный запрос', 'Проверьте правильность запроса');
      } else if (status === 403) {
        handleError('Доступ запрещён', 'У вас нет доступа к этой операции');
      } else {
        handleError('Ошибка', 'Произошла неизвестная ошибка. Попробуйте позже');
      }
    } else {
      throw err;
    }
  }
}

export default function* transactionsSagas() {
  yield all([
    yield takeLatest(transactionsActionTypes.TRANSACTIONS_FETCH_REQUEST, transactionsFetch),
    yield takeLatest(transactionsActionTypes.TRANSACTIONS_CONFIRM_REQUEST, confirm),
    yield takeLatest(transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_REQUEST, createReport),
    yield takeLatest(transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_REQUEST, createPaymentOrder),
    yield takeLatest(transactionsActionTypes.TRANSACTIONS_FETCH_COUNTERS_REQUEST, fetchCounters),
    yield takeLatest(transactionsActionTypes.TRANSACTIONS_FETCH_CHANNEL_LIMITS_REQUEST, fetchChannelLimits),
  ])
}
