import { all, call, put, select } from 'redux-saga/effects'
import employeesActionTypes from './employeesActionTypes'
import { takeLatest } from '@redux-saga/core/effects'
import { network } from "../network";
import { toast } from 'utils/toast';
import { employeeStatus } from './modal/employeeStatus';
import userActionTypes from 'store/user/userActionTypes';

function* update(action) {
  const { id, phone, full_name, password, email, position, permissions } = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/employees/${id}`,
      method: 'PATCH',
      data: {
        phone,
        full_name,
        password,
        email,
        position,
        permissions
      }
    })

    yield put({
      type: employeesActionTypes.EMPLOYEE_UPDATE_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось изменить',
        text: 'Произошла ошибка. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_UPDATE_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }
    
    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_UPDATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_UPDATE_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    yield put({
        type: employeesActionTypes.EMPLOYEE_UPDATE_FAIL,
        payload: err.response.data,
      })
      alertError()

    // throw err
  }
}

function* create(action) {
  const { phone, full_name, password, email, position, permissions } = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/employees`,
      method: 'POST',
      data: {
        phone,
        full_name,
        password,
        email,
        position,
        permissions,
      }
    })

    yield put({
      type: employeesActionTypes.EMPLOYEE_CREATE_FULFILLED,
      payload: {},
    })

    toast.add({
      title: 'Сотрудник добавлен',
      text: 'Письмо с подтверждением отправлено на указанный email сотрудника',
      color: 'primary',
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось добавить',
        text: 'Произошла ошибка. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_CREATE_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }
    
    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_CREATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "UserAlreadyExist") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_CREATE_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "EmployeeEmailAlreadyExists") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_CREATE_FAIL,
        payload: err.response.data,
      })

      toast.add({
        title: 'Не удалось добавить',
        text: 'Сотрудник c данным email уже существует',
        color: 'danger',
      })

      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_CREATE_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* fetch(action) {
  const {id} = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/employees/${id}`,
      method: 'GET',
    })
    yield put({
      type: employeesActionTypes.EMPLOYEE_FETCH_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_FETCH_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "EmployeeNotFound") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_FETCH_FAIL,
        payload: err.response.data,
      })
      toast.add({
        title: 'Ошибка',
        text: err.response.data.message,
        color: 'danger',
      })
      return
    }

    throw err
  }
}

function* fetchList(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/employees`,
      method: 'GET',
    })

    yield put({
      type: employeesActionTypes.EMPLOYEES_FETCH_LIST_FULFILLED,
      payload: {
        list: response.data
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: employeesActionTypes.EMPLOYEES_FETCH_LIST_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: employeesActionTypes.EMPLOYEES_FETCH_LIST_FAIL,
        payload: true,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: employeesActionTypes.EMPLOYEES_FETCH_LIST_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchLogs(action) {
  const {offset, limit, dateFilter, search} = action.payload

  const params = {}

  if (dateFilter) {
    params.date_from = dateFilter.date_from
    params.date_to = dateFilter.date_to
  }

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/logs`,
      method: 'GET',
      params: {
        limit,
        offset,
        search,
        ...params
      }
    })

    yield put({
      type: employeesActionTypes.EMPLOYEES_FETCH_LOGS_FULFILLED,
      payload: {
        logs: response.data
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: employeesActionTypes.EMPLOYEES_FETCH_LOGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: employeesActionTypes.EMPLOYEES_FETCH_LOGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchLogsByID(action) {
  const { limit, employee_id } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/logs`,
      method: 'GET',
      params: {
        limit,
        employee_id,
      }
    })

    yield put({
      type: employeesActionTypes.EMPLOYEES_FETCH_LOGS_BY_ID_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: employeesActionTypes.EMPLOYEES_FETCH_LOGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: employeesActionTypes.EMPLOYEES_FETCH_LOGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* updateEmployeeStatus(action) {
  const { status, id } = action.payload
  const isActive = employeeStatus.active.id === status

  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/employees/${id}/status`,
      method: 'PATCH',
      data: {
        status
      }
    })

    yield put({
      type: employeesActionTypes.EMPLOYEE_UPDATE_STATUS_FULFILLED,
      payload: response.data,
    })

    toast.add({
      title: isActive ? 'Сотрудник разблокирован' : 'Сотрудник заблокирован',
      text: isActive ? 'Доступ к личному кабинет восстановлен.' : 'Это ограничит сотруднику доступ к личному кабинету. История действий сотрудника останется доступной.',
      color: 'primary',
    })

  } catch (err) {
    const alertBlockError = () => {
      toast.add({
        title: isActive ? 'Не удалось разблокировать' : 'Не удалось заблокировать',
        text: 'Произошла ошибка. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_UPDATE_STATUS_FAIL,
        payload: err.response.data,
      })
      alertBlockError()
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "CannotBlockYourself") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_UPDATE_STATUS_FAIL,
        payload: err.response.data,
      })
      toast.add({
        title: 'Ошибка',
        text: err.response.data.message,
        color: 'danger',
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "EmployeeNotFound") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_UPDATE_STATUS_FAIL,
        payload: err.response.data,
      })
      alertBlockError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: employeesActionTypes.EMPLOYEE_UPDATE_STATUS_FAIL,
        payload: err.response.data,
      })
      alertBlockError()
      return
    }
    
    throw err
  }
}

export default function* employeesSagas() {
  yield all([
    yield takeLatest(employeesActionTypes.EMPLOYEE_UPDATE_REQUEST, update),
    yield takeLatest(employeesActionTypes.EMPLOYEE_FETCH_REQUEST, fetch),
    yield takeLatest(employeesActionTypes.EMPLOYEE_CREATE_REQUEST, create),
    yield takeLatest(employeesActionTypes.EMPLOYEES_FETCH_LIST_REQUEST, fetchList),
    yield takeLatest(employeesActionTypes.EMPLOYEES_FETCH_LOGS_REQUEST, fetchLogs),
    yield takeLatest(employeesActionTypes.EMPLOYEES_FETCH_LOGS_BY_ID_REQUEST, fetchLogsByID),
    yield takeLatest(employeesActionTypes.EMPLOYEE_UPDATE_STATUS_REQUEST, updateEmployeeStatus),
  ])
}
