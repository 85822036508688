import React from 'react';
import styled from "styled-components";
import {
  EuiPageBody,
} from '@elastic/eui';
import NotFound from 'components/NotFound/NotFound';
import dashboardGearIcon from 'assets/images/dashboard-gear-primary.svg'

const PageBody = styled(EuiPageBody)`
  background-color: ${({theme}) => theme.colors.colorEmptyShade};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh / var(--page-zoom));
`;

const Engineering = styled(NotFound)`
  padding: 90px 20px 90px;
  .euiText {
    max-width: 400px;
  }
  @media ${({ theme }) => theme.media.phone} {
    .euiIcon {
      max-width: 56px;
    }
  }
`;

export function EngineeringWorks() {
  return (
    <PageBody>
      <Engineering 
        icon={dashboardGearIcon}
        title='Ведутся технические работы'
        description={<>Прямо сейчас Mozen становится лучше для вас. В ближайшее время сервис снова будет доступен!<br /><br />Приносим извинения за временные неудобства.</>}
      />
    </PageBody>
  );
}

