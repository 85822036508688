export const driverStatus = {
  "registered": {
    id: 'registered',
    translate: 'Зарегистрирован'
  },
  "new": {
    id: 'new',
    translate: 'Новый в этом месяце',
  },
  "active": {
    id: 'active',
    translate: 'Активные'
  },
  "no_requisites": {
    id: 'no_requisites',
    translate: 'Без реквизитов'
  },
  "no_contract": {
    id: 'no_contract',
    translate: 'Без договора'
  },
}
