import React from 'react';
import styled from 'styled-components';
import {
  EuiFormRow,
  EuiFieldNumber,
  EuiIconTip
} from '@elastic/eui';

const Wrapper = styled.div`
  .euiFormLabel {
    font-weight: 700;
  }
  .euiFieldText--compressed {
    padding: 8px 12px;
  }
  .euiFormRow__text {
    line-height: 18px;
  }
`;

const Label = styled.span`
  display: flex;
  .euiToolTipAnchor {
    svg {
      width: 12px;
      height: 12px;
      transform: translate(0, -4px);
      margin-left: 7px;
      color: ${({ theme }) => theme.colors.colorDarkShade};
    }
  }
  @media ${({ theme }) => theme.media.phone} {
    .euiToolTipAnchor {
      display: none;
    }
  }
`;

export default (props) => {
  const { label, placeholder, disabled, compressed, caption, fieldValue, className, append, onChangeValue, step, min, max, error, tipContent } = props
  const errors = error ? [error] : []
  const isInvalid = !!error

  return (
    <Wrapper className={className}>
      <EuiFormRow
        label={
          <Label>
            {label}
            {tipContent && <EuiIconTip position='right' content={tipContent} />}
          </Label>
        }
        fullWidth
        isInvalid={isInvalid}
        error={errors}
        helpText={caption}
      >
        <EuiFieldNumber
          compressed={compressed}
          disabled={disabled}
          placeholder={placeholder}
          value={fieldValue}
          fullWidth
          append={append}
          onChange={(e) => onChangeValue(e.target.value)}
          step={step}
          min={min}
          max={max}
          isInvalid={isInvalid}
        />
      </EuiFormRow>
    </Wrapper>
  )
}
