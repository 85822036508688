// Исключительное право на программный код принадлежит ООО «МОЗЕН» (ИНН: 7805760864; ОГРН: 1197847252580)
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@elastic/charts/dist/theme_only_light.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, history } from "./store/store";
import { ThemeProvider } from "styled-components";
import LightTheme from "./themes/LightTheme";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {
  EuiContext,
} from '@elastic/eui'
import { ConnectedRouter } from 'connected-react-router'
import {TimerProvider} from "./containers/SettingsPage/components/TimerProvider";

const i18n = {
  mapping: {
    'euiTablePagination.rowsPerPage': 'Строк на странице',
    'euiTablePagination.rowsPerPageOption': '{rowsPerPage} Строк',
    'euiFilePicker.removeSelected': 'Удалить',
    'euiFieldPassword.showPassword': 'Показать пароль',
    'euiFieldPassword.maskPassword': 'Скрыть пароль',
    'euiComboBoxOptionsList.allOptionsSelected': 'Вы выбрали все доступные варианты',
    'euiPagination.pageOfTotalCompressed': '{page} из {total}'
  },
  formatNumber: (value) => new Intl.NumberFormat('ru').format(value),
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={LightTheme}>
      <ConnectedRouter history={history}>
        <EuiContext i18n={i18n}>
          <TimerProvider>
            <App />
          </TimerProvider>
        </EuiContext>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.querySelector("#root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
