import receiptActionTypes from "./receiptActionTypes";

const initialState = {
  sno: {
    loading: false,
    data: null,
  },

  cloudCashbox: {
    loading: false,
    data: null,
  },

  blockCloudCashbox: {
    loading: false,
  }
}

const reducers = {
  [receiptActionTypes.RECEIPT_FETCH_SNO_REQUEST]: (state, payload) => {
    return {
      ...state,
      sno: {
        ...state.sno,
        loading: true,
        data: null,
      }
    }
  },
  [receiptActionTypes.RECEIPT_FETCH_SNO_FULFILLED]: (state, payload) => {
    return {
      ...state,
      sno: {
        ...state.sno,
        loading: false,
        data: payload.sno,
      }
    }
  },
  [receiptActionTypes.RECEIPT_FETCH_SNO_FAIL]: (state, payload) => {
    return {
      ...state,
      sno: {
        ...state.sno,
        loading: false,
        data: null,
      }
    }
  },

  [receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_REQUEST]: (state, payload) => {
    return {
      ...state,
      cloudCashbox: {
        ...state.cloudCashbox,
        loading: true,
        data: null,
      }
    }
  },
  [receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_FULFILLED]: (state, payload) => {
    return {
      ...state,
      cloudCashbox: {
        ...state.cloudCashbox,
        loading: false,
        data: payload.cloudCashbox,
      }
    }
  },
  [receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_FAIL]: (state, payload) => {
    return {
      ...state,
      cloudCashbox: {
        ...state.cloudCashbox,
        loading: false,
        data: null,
      }
    }
  },

  
  [receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_REQUEST]: (state, payload) => {
    return {
      ...state,
      blockCloudCashbox: {
        ...state.blockCloudCashbox,
        loading: true,
      }
    }
  },
  [receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_FULFILLED]: (state, payload) => {
    return {
      ...state,
      blockCloudCashbox: {
        ...state.blockCloudCashbox,
        loading: false,
      }
    }
  },
  [receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_FAIL]: (state, payload) => {
    return {
      ...state,
      blockCloudCashbox: {
        ...state.blockCloudCashbox,
        loading: false,
      }
    }
  },

}

export default function receiptReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
