import employeesActionTypes from './employeesActionTypes.js'

const initialState = {
  update: {
    loading: false,
  },
  list: {
    loading: false,
    data: null,
    accessDenied: false
  },
  logs: {
    loading: false,
    data: null
  },
}

const reducers = {
  [employeesActionTypes.EMPLOYEE_UPDATE_REQUEST]: (state, payload) => {
    return {
      ...state,
      update: {
        loading: true,
      }
    }
  },
  [employeesActionTypes.EMPLOYEE_UPDATE_FULFILLED]: (state, payload) => {
    return {
      ...state,
      update: {
        loading: false,
      }
    }
  },
  [employeesActionTypes.EMPLOYEE_UPDATE_FAIL]: (state, payload) => {
    return {
      ...state,
      update: {
        loading: false,
      }
    }
  },

  [employeesActionTypes.EMPLOYEES_FETCH_LIST_REQUEST]: (state, payload) => {
    return {
      ...state,
      list: {
        ...state.list,
        loading: true,
        data: null,
        accessDenied: false
      }
    }
  },
  [employeesActionTypes.EMPLOYEES_FETCH_LIST_FULFILLED]: (state, payload) => {
    return {
      ...state,
      list: {
        ...state.list,
        loading: false,
        data: payload.list,
        accessDenied: false
      }
    }
  },
  [employeesActionTypes.EMPLOYEES_FETCH_LIST_FAIL]: (state, payload) => {
    return {
      ...state,
      list: {
        ...state.list,
        loading: false,
        data: null,
        accessDenied: payload
      }
    }
  },

  [employeesActionTypes.EMPLOYEES_FETCH_LOGS_REQUEST]: (state, payload) => {
    return {
      ...state,
      logs: {
        ...state.logs,
        loading: true,
        data: null,
      }
    }
  },
  [employeesActionTypes.EMPLOYEES_FETCH_LOGS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      logs: {
        ...state.logs,
        loading: false,
        data: payload.logs,
      }
    }
  },
  [employeesActionTypes.EMPLOYEES_FETCH_LOGS_FAIL]: (state, payload) => {
    return {
      ...state,
      logs: {
        ...state.logs,
        loading: false,
        data: null,
      }
    }
  },
}

export default function employeesReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
