const autoregActionTypes = {
    WORK_RULES_REQUEST: 'WORK_RULES_REQUEST',
    WORK_RULES_FULFILLED: 'WORK_RULES_FULFILLED',
    WORK_RULES_FAIL: 'WORK_RULES_FAIL',

    FETCH_WORK_RULES_REQUEST: 'FETCH_WORK_RULES_REQUEST',
    FETCH_WORK_RULES_FULFILLED: 'FETCH_WORK_RULES_FULFILLED',
    FETCH_WORK_RULES_FAIL: 'FETCH_WORK_RULES_FAIL',

    UPDATE_WORK_RULES_REQUEST: 'UPDATE_WORK_RULES_REQUEST',
    UPDATE_WORK_RULES_FULFILLED: 'UPDATE_WORK_RULES_FULFILLED',
    UPDATE_WORK_RULES_FAIL: 'UPDATE_WORK_RULES_FAIL',

    DELETE_SETTINGS_REQUEST: 'DELETE_SETTINGS_REQUEST',
    DELETE_SETTINGS_FULFILLED: 'DELETE_SETTINGS_FULFILLED',
    DELETE_SETTINGS_FAIL: 'DELETE_SETTINGS_FAIL',

    UPDATE_AUTO_CONFIRMATION_REQUEST: 'UPDATE_AUTO_CONFIRMATION_REQUEST',
    UPDATE_AUTO_CONFIRMATION_FULFILLED: 'UPDATE_AUTO_CONFIRMATION_FULFILLED',
    UPDATE_AUTO_CONFIRMATION_FAIL: 'UPDATE_AUTO_CONFIRMATION_FAIL',

    FETCH_DRIVERS_REQUEST: 'FETCH_DRIVERS_REQUEST',
    FETCH_DRIVERS_FULFILLED: 'FETCH_DRIVERS_FULFILLED',
    FETCH_DRIVERS_FAIL: 'FETCH_DRIVERS_FAIL',

    CONFIRM_DRIVER_REQUEST_REQUEST: 'CONFIRM_DRIVER_REQUEST_REQUEST',
    CONFIRM_DRIVER_REQUEST_FULFILLED: 'CONFIRM_DRIVER_REQUEST_FULFILLED',
    CONFIRM_DRIVER_REQUEST_FAIL: 'CONFIRM_DRIVER_REQUEST_FAIL',

    DECLINE_DRIVER_REQUEST_REQUEST: 'DECLINE_DRIVER_REQUEST_REQUEST',
    DECLINE_DRIVER_REQUEST_FULFILLED: 'DECLINE_DRIVER_REQUEST_FULFILLED',
    DECLINE_DRIVER_REQUEST_FAIL: 'DECLINE_DRIVER_REQUEST_FAIL',

    FETCH_DRIVER_BY_PHONE_REQUEST: 'FETCH_DRIVER_BY_PHONE_REQUEST',
    FETCH_DRIVER_BY_PHONE_FULFILLED: 'FETCH_DRIVER_BY_PHONE_FULFILLED',
    FETCH_DRIVER_BY_PHONE_FAIL: 'FETCH_DRIVER_BY_PHONE_FAIL',

    FETCH_COUNTERS_REQUEST: 'FETCH_COUNTERS_REQUEST',
    FETCH_COUNTERS_FULFILLED: 'FETCH_COUNTERS_FULFILLED',
    FETCH_COUNTERS_FAIL: 'FETCH_COUNTERS_FAIL',

    SAVE_SELECTED_SETTINGS: 'SAVE_SELECTED_SETTINGS',
};

export default autoregActionTypes;