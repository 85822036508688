import {takeLatest, takeEvery} from "@redux-saga/core/effects";
import autoregActionTypes from "./autoregActionTypes";
import { call, put, all } from "redux-saga/effects"
import {network} from "../network";
import parkActionTypes from "../park/parkActionTypes";
import {toast} from "../../utils/toast";
import fuelActionTypes from "../fuel/fuelActionTypes";
import appActionTypes from "../app/appActionTypes";


function* fetchWorkRules (action) {
    const { aggregatorId, rulesType } = action.payload;

    try {
        const response = yield call(network.appAxios, {
            url: '/erp/1/autoreg/get-work-rules',
            method: 'GET',
            params: {
               park_id_aggregator: aggregatorId
            }
        })

        yield put({
            type: autoregActionTypes.WORK_RULES_FULFILLED,
            payload: {
                rulesType,
                data: response.data
            }
        });
    } catch (err) {

        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: autoregActionTypes.WORK_RULES_FAIL,
                payload: {
                    rulesType,
                    accessDenied: true
                },
            })
            return
        }
    }
}

function* fetchWorkRulesRequest (action) {
    const { aggregatorId, rulesType } = action.payload;

    try {
        const response = yield call(network.appAxios, {
            url: '/erp/1/autoreg/get-work-rules',
            method: 'GET',
            params: {
                park_id_aggregator: aggregatorId
            }
        })

        yield put({
            type: autoregActionTypes.FETCH_WORK_RULES_FULFILLED,
            payload: {
                rulesType,
                data: response.data
            }
        });
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: autoregActionTypes.FETCH_WORK_RULES_FAIL,
                payload: {
                    rulesType,
                    accessDenied: true
                },
            })
            return
        }
    }
}

function* deleteSettings(action) {
    const userTypes = action.payload

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/1/autoreg/delete-autoreg-settings`,
            method: 'DELETE',
            data: {
               user_types: userTypes
            },
        })

        yield put({
            type: autoregActionTypes.DELETE_SETTINGS_FULFILLED,
            payload: response.data,
        })

        // toast.add({
        //     title: 'Изменения сохранены',
        //     text: 'Данные учетной записи агрегатора успешно сохранены',
        //     color: 'success',
        // })
    } catch (err) {
        const alertError = () => {
            toast.add({
                title: 'Не удалось удалить настройки',
                text: 'Сбой операции. Пожалуйста, повторите попытку позже',
                color: 'danger',
            })
        }

        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: autoregActionTypes.DELETE_SETTINGS_FAIL,
                payload: err.response.data,
            })
            alertError()
            return
        }

        if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
            yield put({
                type: autoregActionTypes.DELETE_SETTINGS_FAIL,
                payload: err.response.data,
            })
            alertError()
            return
        }

        throw err
    }
}

function* updateWorkRules(action) {
    const {data} = action.payload
    try {
        const response = yield call(network.appAxios, {
            url: `erp/1/autoreg/set-autoreg-settings`,
            method: 'POST',
            data: {
                autoreg_settings: {
                    work_rule_settings: data
                }
            },
        })

        yield put({
            type: autoregActionTypes.UPDATE_WORK_RULES_FULFILLED,
            payload: response.data,
        })

        // toast.add({
        //     title: 'Изменения сохранены',
        //     text: 'Данные учетной записи агрегатора успешно сохранены',
        //     color: 'success',
        // })
    } catch (err) {
        const alertError = () => {
            toast.add({
                title: 'Не удалось обновить',
                text: 'Сбой операции. Пожалуйста, повторите попытку позже',
                color: 'danger',
            })
        }

        if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
            yield put({
                type: autoregActionTypes.UPDATE_WORK_RULES_FAIL,
                payload: err.response.data,
            })
            alertError()
            return
        }

        if (err.response && err.response.status === 404 && err.response.data.code === "YandexParkNotFound") {
            yield put({
                type: autoregActionTypes.UPDATE_WORK_RULES_FAIL,
                payload: err.response.data,
            })
            alertError()
            return
        }

        if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
            yield put({
                type: autoregActionTypes.UPDATE_WORK_RULES_FAIL,
                payload: err.response.data,
            })
            alertError()
            return
        }

        if (err.response && err.response.status === 400 && err.response.data.code === "YandexFleetApiUnavailable") {
            yield put({
                type: autoregActionTypes.UPDATE_WORK_RULES_FAIL,
                payload: err.response.data,
            })
            alertError()
            return
        }

        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: autoregActionTypes.UPDATE_WORK_RULES_FAIL,
                payload: {accessDenied: true},
            })

            toast.add({
                title: 'Ошибка',
                text: 'Доступ запрещен.',
                color: 'danger',
            });

            return;
        }

        if (err.response && err.response.status === 403 && err.response.data.code === "PermissionDenied") {
            yield put({
                type: autoregActionTypes.UPDATE_WORK_RULES_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        throw err
    }
}

// function* updateWorkRules (action) {
//     const { type ,aggregatorId } = action.payload;
//
//     try {
//         const response = yield call(network.appAxios, {
//             url: '/erp/1/park/aggregator/${id}',
//             method: 'GET',
//             params: {
//                 park_id_aggregator: aggregatorId
//             }
//         })
//
//         yield put({
//             type: autoregActionTypes.WORK_RULES_FULFILLED,
//             payload: response.data
//         });
//     } catch (e) {}
// }

function* updateAutoConfirmation (action) {
    const {auto_confirm} = action.payload

    try {
        const response = yield call(network.appAxios, {
            url: 'erp/1/autoreg/set-auto-confirm',
            method: 'POST',
            data: {
                auto_confirm
            }
        })

        yield put({
            type: autoregActionTypes.UPDATE_AUTO_CONFIRMATION_FULFILLED,
            payload: response.data
        });
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: autoregActionTypes.UPDATE_AUTO_CONFIRMATION_FAIL,
                payload: {accessDenied: true},
            });

            toast.add({
                title: 'Ошибка',
                text: 'Доступ запрещен.',
                color: 'danger',
            })

            return;
        }
    }
}

function* fetchDrivers(action) {
    const { payload } = action
    const { limit, offset, search, statuses, userTypes } = payload

    var params = new URLSearchParams();
    params.append("limit", limit);
    params.append("offset", offset);
    params.append("search", search);

    if (userTypes && userTypes.length) {
        userTypes?.forEach(type => params.append('user_type[]', type));
    }

    statuses?.forEach(status => params.append('status', status));

    try {
        const response = yield call(network.appAxios, {
            url: `erp/1/autoreg/get-drivers`,
            method: 'GET',
            params
        })

        yield put({
            type: autoregActionTypes.FETCH_DRIVERS_FULFILLED,
            payload: response.data
        })
    } catch (err) {
        if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
            yield put({
                type: autoregActionTypes.FETCH_DRIVERS_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 404 && err.response.data.code === "FuelDepositHistoryNotFound") {
            yield put({
                type: autoregActionTypes.FETCH_DRIVERS_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
            yield put({
                type: autoregActionTypes.FETCH_DRIVERS_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: autoregActionTypes.FETCH_DRIVERS_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        throw err
    }
}

function* fetchCounters(action) {
    const { payload } = action

    try {
        const response = yield call(network.appAxios, {
            url: `erp/1/autoreg/counter`,
            method: 'GET',
        })

        yield put({
            type: autoregActionTypes.FETCH_COUNTERS_FULFILLED,
            payload: response.data
        })
    } catch (err) {
        if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
            yield put({
                type: autoregActionTypes.FETCH_COUNTERS_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 404 && err.response.data.code === "FuelDepositHistoryNotFound") {
            yield put({
                type: autoregActionTypes.FETCH_COUNTERS_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
            yield put({
                type: autoregActionTypes.FETCH_COUNTERS_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: autoregActionTypes.FETCH_COUNTERS_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        throw err
    }
}

function* modifyDriversRequests(action) {
    const { payload } = action
    const { drivers, approved } = payload

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/1/autoreg/approve-driver`,
            method: 'POST',
            data: {
                drivers: drivers.map((data) => ({
                    ...data,
                    approved
                }))
            }
        })

        yield put({
            type: approved ? autoregActionTypes.CONFIRM_DRIVER_REQUEST_FULFILLED : autoregActionTypes.DECLINE_DRIVER_REQUEST_FULFILLED,
            payload: response.data
        });

        yield put({
            type: autoregActionTypes.FETCH_COUNTERS_REQUEST,
            payload: {},
        })
    } catch (err) {

        if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
            yield put({
                type: approved ? autoregActionTypes.CONFIRM_DRIVER_REQUEST_FAIL: autoregActionTypes.DECLINE_DRIVER_REQUEST_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 404 && err.response.data.code === "FuelDepositHistoryNotFound") {
            yield put({
                type: approved ? autoregActionTypes.CONFIRM_DRIVER_REQUEST_FAIL : autoregActionTypes.DECLINE_DRIVER_REQUEST_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
            yield put({
                type: approved ? autoregActionTypes.CONFIRM_DRIVER_REQUEST_FAIL : autoregActionTypes.DECLINE_DRIVER_REQUEST_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: approved ? autoregActionTypes.CONFIRM_DRIVER_REQUEST_FAIL : autoregActionTypes.DECLINE_DRIVER_REQUEST_FAIL,
                payload: {accessDenied: true},
            });

            toast.add({
                title: 'Ошибка',
                text: 'Доступ запрещен.',
                color: 'danger',
            });

            return
        }

        throw err
    }
}

function* fetchDriver(action) {
    const { payload } = action
    const { id } = payload

    try {
        const response = yield call(network.appAxios, {
            url: `erp/1/autoreg/get-driver-by-id`,
            method: 'GET',
            params: {
                id: id,
            }
        })

        yield put({
            type: autoregActionTypes.FETCH_DRIVER_BY_PHONE_FULFILLED,
            payload: response.data
        })
    } catch (err) {
        if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
            yield put({
                type: autoregActionTypes.FETCH_DRIVER_BY_PHONE_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 404 && err.response.data.code === "FuelDepositHistoryNotFound") {
            yield put({
                type: autoregActionTypes.FETCH_DRIVER_BY_PHONE_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
            yield put({
                type: autoregActionTypes.FETCH_DRIVER_BY_PHONE_FAIL,
                payload: err.response.data,
            })
            return
        }

        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: autoregActionTypes.FETCH_DRIVER_BY_PHONE_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        throw err
    }
}


export default function* autoregSagas() {
    yield all([
        yield takeEvery(autoregActionTypes.WORK_RULES_REQUEST, fetchWorkRules),
        yield takeLatest(autoregActionTypes.FETCH_WORK_RULES_REQUEST, fetchWorkRulesRequest),
        yield takeLatest(autoregActionTypes.FETCH_DRIVERS_REQUEST, fetchDrivers),
        yield takeLatest(autoregActionTypes.FETCH_COUNTERS_REQUEST, fetchCounters),
        yield takeLatest(autoregActionTypes.UPDATE_WORK_RULES_REQUEST, updateWorkRules),
        yield takeLatest(autoregActionTypes.DELETE_SETTINGS_REQUEST, deleteSettings),
        yield takeLatest(autoregActionTypes.UPDATE_AUTO_CONFIRMATION_REQUEST, updateAutoConfirmation),
        yield takeLatest(autoregActionTypes.CONFIRM_DRIVER_REQUEST_REQUEST, modifyDriversRequests),
        yield takeLatest(autoregActionTypes.DECLINE_DRIVER_REQUEST_REQUEST, modifyDriversRequests),
        yield takeLatest(autoregActionTypes.FETCH_DRIVER_BY_PHONE_REQUEST, fetchDriver),
    ])
}