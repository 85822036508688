import React, { useState } from 'react';
import styled from 'styled-components';
import {
    EuiIcon,
    EuiRadioGroup,
} from '@elastic/eui';
import Callout from 'components/Callout/Callout';
import NumberInput from 'components/NumberInput/NumberInput';
import { Field, Form } from "react-final-form";
import { validation } from 'utils/validation';
import { PrimaryButton, PrimaryEmptyButton } from 'components/Buttons'
import { Modal } from 'components/Modals'
import { fixDecimalPrecision } from 'utils/fixDecimalPrecision';
import KnowledgeDatabaseLink from "../../../components/KnowledgeDatabaseLink/KnowledgeDatabaseLink";
import parkActions from "../../../store/park/parkActions";

const ModalCallout = styled(Callout)`
  &.danger {
    background-color: ${({ theme }) => theme.colors.colorDangerLight};
    .euiCallOutHeader__title {
      color: ${({ theme }) => theme.colors.colorDanger};
    }
    .euiCallOutHeader__icon {
      fill: ${({ theme }) => theme.colors.colorDanger};
    }
  }
  margin: 16px 0 4px;
`;

const PrimaryCheckIcon = styled(EuiIcon)`
  color: ${({ theme }) => theme.colors.colorPrimary};
  margin-right: 15px;
`;

const paymentCallout = {
    title: 'Обратите внимание',
    message: <>Ручное изменение значений в полях сформированного документа может привести к более длительной обработке
        платежа или невозможности автоматической обработки.<br/><br/> Подробнее о всех способах пополнения
        счета <KnowledgeDatabaseLink postfix={'erp/tochka-pay'}>вы можете прочитать в базе
            знаний.</KnowledgeDatabaseLink></>,
    color: 'danger',
    icon: 'alert'
}

export const TochkaDepositModal = (props) => {
    const { className, parkPaymentOrder, parkWithdrawToDeposit, isModalOpen, setIsModalOpen } = props
    const [topupAmount, setTopupAmount] = useState('')

    const [isSuccessPayment, setIsSuccessPayment] = useState(false)
    const loading = parkPaymentOrder?.loading || parkWithdrawToDeposit?.loading

    const onCloseTopup = () => {
        setIsModalOpen(false)
        setIsSuccessPayment(false)
    }

    const topupModalSecondaryAction = () => {
        if (isSuccessPayment) {
            setIsSuccessPayment(false)
            setTopupAmount('')
            return
        }
        onCloseTopup()
    }

    const onSubmitTopup = async (e) => {
        const amount = e.amount
        const amountWithDecimal = fixDecimalPrecision(amount * 100)
        if (!amount && !isSuccessPayment) {
            return
        }

        try {
            if (!isSuccessPayment) {
                await parkActions.asyncCreatePaymentOrder({
                    payment_order_type: 'park_mozen_nominal_account_topup_tochka',
                    amount: amountWithDecimal
                });

                setTopupAmount(amount)
                setIsSuccessPayment(true)
                return
            }
            onCloseTopup()
        } catch (err) {
            console.log(err)
        }
    }

    let topupForm

    const onChangeTopupValue = (onChange) => (value) => {
        if (!validation.minInt(1)(value) || value === '') {
            onChange(value)
            setTopupAmount(value)
        }
    }

    const topupModalFormData = (
        <>
            <Form
                onSubmit={onSubmitTopup}
                validate={(values) => {
                    const amount = values.amount
                    const errors = {}

                    if (Number(amount) < 100) {
                        errors.amount = 'Минимальная сумма пополнения — 100 рублей'
                    }
                    if (amount?.includes('.')) {
                        errors.amount = 'Укажите сумму без копеек'
                    }
                    return errors
                }}
                render={({ handleSubmit }) => {
                    topupForm = {
                        handleSubmit
                    }

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Field
                                    name='amount'
                                >
                                    {({ input, meta }) => (
                                        <NumberInput
                                            label='Пополнить на сумму'
                                            placeholder='Введите сумму'
                                            caption='Пополнение счета происходит через пополнение номинального счета, поэтому платеж может быть зачислен от пары минут до 3-5 рабочих дней.'
                                            fieldValue={input.value}
                                            onChangeValue={onChangeTopupValue(input.onChange)}
                                            append='₽'
                                            step={100}
                                            error={meta.touched && (meta.error || meta.submitError)}
                                        />
                                    )}
                                </Field>
                            </form>
                        </>
                    )
                }}
            />
            <ModalCallout
                className={paymentCallout.color}
                title={paymentCallout.title}
                message={paymentCallout.message}
                icon={paymentCallout.icon}
            />
        </>
    )

    const topupModalSuccess = <p>Спасибо. Должно автоматически начаться скачивание файла'</p>;

    return (
        <div className={className}>
            {/*{card}*/}
            <Modal
                onClose={onCloseTopup}
                isVisible={isModalOpen}
                title={isSuccessPayment ? 'Платеж создан' : 'Пополнить счет'}
                headerPrepend={isSuccessPayment && <PrimaryCheckIcon type='check' size='l' />}
                body={isSuccessPayment ? topupModalSuccess : topupModalFormData}
                footer={
                    <>
                        <PrimaryEmptyButton
                            onClick={topupModalSecondaryAction}
                            disabled={loading}
                        >
                            {isSuccessPayment ? 'Пополнить еще' : 'Отмена'}
                        </PrimaryEmptyButton>
                        <PrimaryButton
                            disabled={!topupAmount}
                            isLoading={loading}
                            iconType={!isSuccessPayment ? 'download' : ''}
                            onClick={event => !isSuccessPayment ? topupForm.handleSubmit(event) : onCloseTopup()}
                        >
                            {isSuccessPayment ? 'Хорошо' : 'Сформировать счет'}
                        </PrimaryButton>
                    </>
                }
            />
        </div>
    )
}
export default TochkaDepositModal