import styled from 'styled-components';
import {
  EuiButton,
} from '@elastic/eui';

export const DangerEmptyButton = styled(EuiButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primaryDanger};
  font-weight: 400;
  min-width: auto;
  &.euiButton {
    &:hover, &:focus-within {
      background-color: transparent;
    }
  }
  &.euiButton-isDisabled:not(.euiButton--ghost) {
    background: transparent;
    &:hover, &:focus-within {
      background: transparent;
    }
  }
`;
