import React from 'react';
import styled from 'styled-components';
import {
  EuiButton,
} from '@elastic/eui';

export const BrownButton = styled(props => <EuiButton color='warning' {...props} />)`
  color: ${({ theme }) => theme.colors.brown2};
  background-color: ${({ theme }) => theme.colors.brownLight2};
`;
