import { call, all, put } from 'redux-saga/effects'
import { takeLatest } from '@redux-saga/core/effects'
import receiptActionTypes from "./receiptActionTypes";
import { network } from "../network";
import { toast } from 'utils/toast'

function* fetchSno(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/receipt/sno`,
      method: 'GET',
    })

    yield put({
      type: receiptActionTypes.RECEIPT_FETCH_SNO_FULFILLED,
      payload: {
        sno: response.data,
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: receiptActionTypes.RECEIPT_FETCH_SNO_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchCloudCashbox(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/receipt/cloud-cashbox`,
      method: 'GET',
    })

    yield put({
      type: receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_FULFILLED,
      payload: {
        cloudCashbox: response.data,
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "CloudCashboxNotFound") {
      yield put({
        type: receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* createCloudCashbox(action) {
  const { kpp, name, password, login, sno_id, group_code, payment_address, inn, integration_type } = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/receipt/cloud-cashbox`,
      method: 'POST',
      data: {
        kpp,
        name,
        inn,
        cb_integration: {
          password,
          group_code,
          payment_address,
          integration_type,
          login
        },
        sno_id
      }
    })

    yield put({
      type: receiptActionTypes.RECEIPT_CREATE_CLOUD_CASHBOX_FULFILLED,
      payload: {
        cloudCashbox: response.data,
      },
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось подключить кассу',
        text: 'Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: receiptActionTypes.RECEIPT_CREATE_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "CloudCashboxAlreadyExists") {
      yield put({
        type: receiptActionTypes.RECEIPT_CREATE_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: receiptActionTypes.RECEIPT_CREATE_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: receiptActionTypes.RECEIPT_CREATE_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* updateCloudCashbox(action) {
  const { kpp, name, password, login, sno_id, group_code, payment_address, inn, sno_name, blocked, integration_type } = action.payload

  const params = {}

  if (password && group_code && payment_address && login) {
    params.cb_integration = {
      password,
      group_code,
      payment_address,
      integration_type,
      login
    }
  } else {
    params.blocked = blocked
  }

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/receipt/cloud-cashbox`,
      method: 'PATCH',
      data: {
        kpp,
        name,
        inn,
        sno_id,
        sno_name,
        ...params
      }
    })
    
    yield put({
      type: receiptActionTypes.RECEIPT_UPDATE_CLOUD_CASHBOX_FULFILLED,
      payload: response.data,
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось отредактировать',
        text: 'Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: receiptActionTypes.RECEIPT_UPDATE_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }
    
    if (err.response && err.response.status === 404 && err.response.data.code === "CloudCashboxNotFound") {
      yield put({
        type: receiptActionTypes.RECEIPT_UPDATE_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* blockCloudCashbox(action) {
  const { blocked } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/receipt/cloud-cashbox/block`,
      method: 'PATCH',
      data: {
        blocked
      }
    })

    yield put({
      type: receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_FULFILLED,
      payload: response.data,
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: blocked ? 'Не удалось включить кассу' : 'Не удалось отключить кассу',
        text: 'Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }
    
    if (err.response && err.response.status === 404 && err.response.data.code === "CloudCashboxNotFound") {
      yield put({
        type: receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}


export default function* receiptSagas() {
  yield all([
    yield takeLatest(receiptActionTypes.RECEIPT_FETCH_SNO_REQUEST, fetchSno),
    yield takeLatest(receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_REQUEST, fetchCloudCashbox),
    yield takeLatest(receiptActionTypes.RECEIPT_CREATE_CLOUD_CASHBOX_REQUEST, createCloudCashbox),
    yield takeLatest(receiptActionTypes.RECEIPT_UPDATE_CLOUD_CASHBOX_REQUEST, updateCloudCashbox),
    yield takeLatest(receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_REQUEST, blockCloudCashbox),
  ])
}
