import { call, all, put } from 'redux-saga/effects'
import { takeLatest } from '@redux-saga/core/effects'
import subscriptionActionTypes from "./subscriptionActionTypes";
import { network } from "../network";

function* fetchSummary(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/2/park/subscription/summary`,
      method: 'GET',
    })

    yield put({
      type: subscriptionActionTypes.SUBSCRIPTION_SUMMARY_FULFILLED,
      payload: {
        summary: response.data,
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: subscriptionActionTypes.SUBSCRIPTION_SUMMARY_FAIL,
        payload: err.response.data,
      })
      return
    }
    throw err
  }
  
}

function* fetchPayments(action) {
  const {limit, offset, sort_direction} = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/subscription/payments`,
      method: 'GET',
      params: {
        limit,
        offset,
        sort_direction
      }
    })

    yield put({
      type: subscriptionActionTypes.SUBSCRIPTION_FETCH_PAYMENTS_FULFILLED,
      payload: {
        payments: response.data,
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: subscriptionActionTypes.SUBSCRIPTION_FETCH_PAYMENTS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: subscriptionActionTypes.SUBSCRIPTION_FETCH_PAYMENTS_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: subscriptionActionTypes.SUBSCRIPTION_FETCH_PAYMENTS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

export default function* subscriptionSagas() {
  yield all([
    yield takeLatest(subscriptionActionTypes.SUBSCRIPTION_SUMMARY_REQUEST, fetchSummary),
    yield takeLatest(subscriptionActionTypes.SUBSCRIPTION_FETCH_PAYMENTS_REQUEST, fetchPayments),
  ])
}
