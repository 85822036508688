const fuelActionTypes = {
  FUEL_FETCH_DEPOSIT_HISTORY_REQUEST: 'FUEL_FETCH_DEPOSIT_HISTORY_REQUEST',
  FUEL_FETCH_DEPOSIT_HISTORY_FULFILLED: 'FUEL_FETCH_DEPOSIT_HISTORY_FULFILLED',
  FUEL_FETCH_DEPOSIT_HISTORY_FAIL: 'FUEL_FETCH_DEPOSIT_HISTORY_FAIL',

  FUEL_FETCH_FUEL_HISTORY_REQUEST: 'FUEL_FETCH_FUEL_HISTORY_REQUEST',
  FUEL_FETCH_FUEL_HISTORY_FULFILLED: 'FUEL_FETCH_FUEL_HISTORY_FULFILLED',
  FUEL_FETCH_FUEL_HISTORY_FAIL: 'FUEL_FETCH_FUEL_HISTORY_FAIL',

  FUEL_FETCH_FUEL_HISTORY_BENZUBER_REQUEST: 'FUEL_FETCH_FUEL_HISTORY_BENZUBER_REQUEST',
  FUEL_FETCH_FUEL_HISTORY_BENZUBER_FULFILLED: 'FUEL_FETCH_FUEL_HISTORY_BENZUBER_FULFILLED',
  FUEL_FETCH_FUEL_HISTORY_BENZUBER_FAIL: 'FUEL_FETCH_FUEL_HISTORY_BENZUBER_FAIL',


  FUEL_SUMMARY_REQUEST: 'FUEL_SUMMARY_REQUEST',
  FUEL_SUMMARY_FULFILLED: 'FUEL_SUMMARY_FULFILLED',
  FUEL_SUMMARY_FAIL: 'FUEL_SUMMARY_FAIL',

  FUEL_DETAILS_REQUEST: 'FUEL_DETAILS_REQUEST',
  FUEL_DETAILS_FULFILLED: 'FUEL_DETAILS_FULFILLED',
  FUEL_DETAILS_FAIL: 'FUEL_DETAILS_FAIL',

  FUEL_PARK_REQUEST: 'FUEL_PARK_REQUEST',
  FUEL_PARK_FULFILLED: 'FUEL_PARK_FULFILLED',
  FUEL_PARK_FAIL: 'FUEL_PARK_FAIL',

  FUEL_SAVE_LIMITS: 'FUEL_SAVE_LIMITS',

  FUEL_LIMITS_CREATE_REQUEST: 'FUEL_LIMITS_CREATE_REQUEST',
  FUEL_LIMITS_CREATE_FULFILLED: 'FUEL_LIMITS_CREATE_FULFILLED',
  FUEL_LIMITS_CREATE_FAIL: 'FUEL_LIMITS_CREATE_FAIL',

  FUEL_LIMITS_UPDATE_REQUEST: 'FUEL_LIMITS_UPDATE_REQUEST',
  FUEL_LIMITS_UPDATE_FULFILLED: 'FUEL_LIMITS_UPDATE_FULFILLED',
  FUEL_LIMITS_UPDATE_FAIL: 'FUEL_LIMITS_UPDATE_FAIL',

  FUEL_AVAILABILITY_UPDATE_REQUEST: 'FUEL_AVAILABILITY_UPDATE_REQUEST',
  FUEL_AVAILABILITY_UPDATE_FULFILLED: 'FUEL_AVAILABILITY_UPDATE_FULFILLED',
  FUEL_AVAILABILITY_UPDATE_FAIL: 'FUEL_AVAILABILITY_UPDATE_FAIL',

  FUEL_FETCH_ACCOUNT_HISTORY_REQUEST: 'FUEL_FETCH_ACCOUNT_HISTORY_REQUEST',
  FUEL_FETCH_ACCOUNT_HISTORY_FULFILLED: 'FUEL_FETCH_ACCOUNT_HISTORY_FULFILLED',
  FUEL_FETCH_ACCOUNT_HISTORY_FAIL: 'FUEL_FETCH_ACCOUNT_HISTORY_FAIL',
}

export default fuelActionTypes
