import styled from 'styled-components';
import { EuiButton } from '@elastic/eui';

export const PurpleButton = styled(props => <EuiButton color='ghost' {...props} />)`
  color: ${({ theme }) => theme.colors.purple2};
  background-color: ${({ theme }) => theme.colors.backgroundPurple1};
  &.euiButton:disabled {
    background-color: ${({ theme }) => theme.colors.colorDisabledBackground};
    color: ${({ theme }) => theme.colors.colorDisabledText};
  }
  &.euiButton {
    &:not(:disabled):focus-within, &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.colors.backgroundPurple2};
    }
  }
`;
