const transactionsActionTypes = {
  TRANSACTIONS_FETCH_REQUEST: 'TRANSACTIONS_FETCH_REQUEST',
  TRANSACTIONS_FETCH_FULFILLED: 'TRANSACTIONS_FETCH_FULFILLED',
  TRANSACTIONS_FETCH_FAIL: 'TRANSACTIONS_FETCH_FAIL',

  TRANSACTIONS_CONFIRM_REQUEST: 'TRANSACTIONS_CONFIRM_REQUEST',
  TRANSACTIONS_CONFIRM_FULFILLED: 'TRANSACTIONS_CONFIRM_FULFILLED',
  TRANSACTIONS_CONFIRM_FAIL: 'TRANSACTIONS_CONFIRM_FAIL',

  TRANSACTIONS_CREATE_REPORT_REQUEST: 'TRANSACTIONS_CREATE_REPORT_REQUEST',
  TRANSACTIONS_CREATE_REPORT_FULFILLED: 'TRANSACTIONS_CREATE_REPORT_FULFILLED',
  TRANSACTIONS_CREATE_REPORT_FAIL: 'TRANSACTIONS_CREATE_REPORT_FAIL',

  TRANSACTIONS_CREATE_PAYMENT_ORDER_REQUEST: 'TRANSACTIONS_CREATE_PAYMENT_ORDER_REQUEST',
  TRANSACTIONS_CREATE_PAYMENT_ORDER_FULFILLED: 'TRANSACTIONS_CREATE_PAYMENT_ORDER_FULFILLED',
  TRANSACTIONS_CREATE_PAYMENT_ORDER_FAIL: 'TRANSACTIONS_CREATE_PAYMENT_ORDER_FAIL',

  TRANSACTIONS_FETCH_COUNTERS_REQUEST: 'TRANSACTIONS_FETCH_COUNTERS_REQUEST',
  TRANSACTIONS_FETCH_COUNTERS_FULFILLED: 'TRANSACTIONS_FETCH_COUNTERS_FULFILLED',
  TRANSACTIONS_FETCH_COUNTERS_FAIL: 'TRANSACTIONS_FETCH_COUNTERS_FAIL',

  STORE_PAYMENT_DETAILS: 'STORE_PAYMENT_DETAILS',

  TRANSACTIONS_FETCH_CHANNEL_LIMITS_REQUEST: 'TRANSACTIONS_FETCH_CHANNEL_LIMITS_REQUEST',
  TRANSACTIONS_FETCH_CHANNEL_LIMITS_FULFILLED: 'TRANSACTIONS_FETCH_CHANNEL_LIMITS_FULFILLED',
  TRANSACTIONS_FETCH_CHANNEL_LIMITS_FAIL: 'TRANSACTIONS_FETCH_CHANNEL_LIMITS_FAIL',
}

export default transactionsActionTypes
