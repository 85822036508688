const fuelActionTypes = {
  SUBSCRIPTION_FETCH_PAYMENTS_REQUEST: 'SUBSCRIPTION_FETCH_PAYMENTS_REQUEST',
  SUBSCRIPTION_FETCH_PAYMENTS_FULFILLED: 'SUBSCRIPTION_FETCH_PAYMENTS_FULFILLED',
  SUBSCRIPTION_FETCH_PAYMENTS_FAIL: 'SUBSCRIPTION_FETCH_PAYMENTS_FAIL',

  SUBSCRIPTION_SUMMARY_REQUEST: 'SUBSCRIPTION_SUMMARY_REQUEST',
  SUBSCRIPTION_SUMMARY_FULFILLED: 'SUBSCRIPTION_SUMMARY_FULFILLED',
  SUBSCRIPTION_SUMMARY_FAIL: 'SUBSCRIPTION_SUMMARY_FAIL',
}

export default fuelActionTypes
