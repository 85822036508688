const driversActionTypes = {
  DRIVERS_FETCH_REQUEST: 'DRIVERS_FETCH_REQUEST',
  DRIVERS_FETCH_FULFILLED: 'DRIVERS_FETCH_FULFILLED',
  DRIVERS_FETCH_FAIL: 'DRIVERS_FETCH_FAIL',

  DRIVER_FETCH_REQUEST: 'DRIVER_FETCH_REQUEST',
  DRIVER_FETCH_FULFILLED: 'DRIVER_FETCH_FULFILLED',
  DRIVER_FETCH_FAIL: 'DRIVER_FETCH_FAIL',

  DRIVER_FETCH_TRANSACTIONS_REQUEST: 'DRIVER_FETCH_TRANSACTIONS_REQUEST',
  DRIVER_FETCH_TRANSACTIONS_FULFILLED: 'DRIVER_FETCH_TRANSACTIONS_FULFILLED',
  DRIVER_FETCH_TRANSACTIONS_FAIL: 'DRIVER_FETCH_TRANSACTIONS_FAIL',

  DRIVER_FETCH_PAYMENT_SETTINGS_REQUEST: 'DRIVER_FETCH_PAYMENT_SETTINGS_REQUEST',
  DRIVER_FETCH_PAYMENT_SETTINGS_FULFILLED: 'DRIVER_FETCH_PAYMENT_SETTINGS_FULFILLED',
  DRIVER_FETCH_PAYMENT_SETTINGS_FAIL: 'DRIVER_FETCH_PAYMENT_SETTINGS_FAIL',
  
  DRIVER_UPDATE_PAYMENT_SETTINGS_REQUEST: 'DRIVER_UPDATE_PAYMENT_SETTINGS_REQUEST',
  DRIVER_UPDATE_PAYMENT_SETTINGS_FULFILLED: 'DRIVER_UPDATE_PAYMENT_SETTINGS_FULFILLED',
  DRIVER_UPDATE_PAYMENT_SETTINGS_FAIL: 'DRIVER_UPDATE_PAYMENT_SETTINGS_FAIL',

  DRIVER_CREATE_PAYMENT_SETTINGS_REQUEST: 'DRIVER_CREATE_PAYMENT_SETTINGS_REQUEST',
  DRIVER_CREATE_PAYMENT_SETTINGS_FULFILLED: 'DRIVER_CREATE_PAYMENT_SETTINGS_FULFILLED',
  DRIVER_CREATE_PAYMENT_SETTINGS_FAIL: 'DRIVER_CREATE_PAYMENT_SETTINGS_FAIL',
  
  DRIVER_UPDATE_CONTRACT_REQUEST: 'DRIVER_UPDATE_CONTRACT_REQUEST',
  DRIVER_UPDATE_CONTRACT_FULFILLED: 'DRIVER_UPDATE_CONTRACT_FULFILLED',
  DRIVER_UPDATE_CONTRACT_FAIL: 'DRIVER_UPDATE_CONTRACT_FAIL',
  
  DRIVER_DELETE_CONTRACT_REQUEST: 'DRIVER_DELETE_CONTRACT_REQUEST',
  DRIVER_DELETE_CONTRACT_FULFILLED: 'DRIVER_DELETE_CONTRACT_FULFILLED',
  DRIVER_DELETE_CONTRACT_FAIL: 'DRIVER_DELETE_CONTRACT_FAIL',
  
  DRIVERS_DOWNLOAD_NO_CONTRACT_REQUEST: 'DRIVERS_DOWNLOAD_NO_CONTRACT_REQUEST',
  DRIVERS_DOWNLOAD_NO_CONTRACT_FULFILLED: 'DRIVERS_DOWNLOAD_NO_CONTRACT_FULFILLED',
  DRIVERS_DOWNLOAD_NO_CONTRACT_FAIL: 'DRIVERS_DOWNLOAD_NO_CONTRACT_FAIL',
    
  DRIVERS_UPLOAD_CONTRACTS_REQUEST: 'DRIVERS_UPLOAD_CONTRACTS_REQUEST',
  DRIVERS_UPLOAD_CONTRACTS_FULFILLED: 'DRIVERS_UPLOAD_CONTRACTS_FULFILLED',
  DRIVERS_UPLOAD_CONTRACTS_FAIL: 'DRIVERS_UPLOAD_CONTRACTS_FAIL',

  DRIVERS_RESET_UPLOADED_CONTRACTS: 'DRIVERS_RESET_UPLOADED_CONTRACTS',
      
  DRIVERS_COMMIT_CONTRACTS_REQUEST: 'DRIVERS_COMMIT_CONTRACTS_REQUEST',
  DRIVERS_COMMIT_CONTRACTS_FULFILLED: 'DRIVERS_COMMIT_CONTRACTS_FULFILLED',
  DRIVERS_COMMIT_CONTRACTS_FAIL: 'DRIVERS_COMMIT_CONTRACTS_FAIL',

  DRIVERS_DELETE_AUTH_SESSIONS_REQUEST: 'DRIVERS_DELETE_AUTH_SESSIONS_REQUEST',
  DRIVERS_DELETE_AUTH_SESSIONS_FULFILLED: 'DRIVERS_DELETE_AUTH_SESSIONS_FULFILLED',
  DRIVERS_DELETE_AUTH_SESSIONS_FAIL: 'DRIVERS_DELETE_AUTH_SESSIONS_FAIL',
}

export default driversActionTypes
