import React from 'react';
import styled from 'styled-components';
import {
  EuiButton,
} from '@elastic/eui';

export const GreenButton = styled(props => <EuiButton color='success' {...props} />)`
  color: ${({ theme }) => theme.colors.greenDarken};
  background-color: ${({ theme }) => theme.colors.greenBackground};
`;
