const regExp = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  name: /^[-а-яА-Яa-zA-ZёЁ\s]{1,90}$/i,
  driverContract: /^[0-9a-zа-яё/-]*$/i,
  commonName: /^[-/a-zA-ZА-Яа-яЁё0-9\s/—]*$/,
  phone: /^\+7[0-9\b]{10}$/
}

const formatTo = {
  lettersWithSpace: value => value?.replace(/[^a-zA-ZА-Яа-яЁё\s]/gi, ''),
  digitsSubstr1: value => value?.replace(/[^0-9]+/g, '').substr(1),
  digitsWithPlus: value => value?.replace(/[^+\d]/g, '')
}

export { regExp, formatTo }