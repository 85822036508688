import React from 'react';
import styled from "styled-components";
import {
  EuiPageBody,
  EuiTitle,
  EuiLoadingLogo
} from '@elastic/eui';
import logo from 'assets/images/logo-icon.svg'

const PageBody = styled(EuiPageBody)`
  background-color: ${({theme}) => theme.colors.colorEmptyShade};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh / var(--page-zoom));
`;

const FlexGrow1 = styled.div`
  flex-grow: 1;
`;

const LoginForm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

const LoadingLogo = styled(EuiLoadingLogo)`
  margin-bottom: 20px;
  &.euiLoadingLogo--xLarge, .euiLoadingKibana--xLarge {
    width: 44px;
    height: 48px;
  }
  .euiIcon--xLarge {
    width: 44px;
    height: 44px;
  }
`;

const PageTitle = styled(EuiTitle)`
  margin: 12px 0 12px 0;
  text-align: center;
`;

const Hint = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #69707D;
  display: flex;  
  text-align: center;
  @media ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`;

export function Preloader() {
  return (
    <PageBody>
      <FlexGrow1/>

      <LoginForm>
        <LoadingLogo logo={logo} size='xl'/>

        <PageTitle>
          <h1>Добро пожаловать</h1>
        </PageTitle>

        <Hint>
          <div>Подключение к сервису.&nbsp;</div>
          <div>Пожалуйста, подождите...</div>
        </Hint>

      </LoginForm>

      <FlexGrow1/>
    </PageBody>
  );
}

