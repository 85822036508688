import yandexIconNew from 'assets/images/yandex-icon.svg';
import citymobilIcon from "assets/images/citymobil-icon.svg";
import taxovichkofIcon from 'assets/images/taxovichkof-icon.svg';
import gruzovichkofIcon from 'assets/images/gruzovichkof-icon.svg';
import uberIcon from 'assets/images/uber-icon.svg';
import bibiIcon from 'assets/images/bibi-icon.svg';
import bibiIconWhite from 'assets/images/bibi-icon-white.svg';
import driveeIcon from 'assets/images/drivee_28.svg'

export const aggregatorsTypeKeys = {
  YANDEX: "yandex",
  CITYMOBIL: "citymobil",
  TAXOVICHKOF: "taxovichkof",
  BIBI: "bibi",
  DRIVEE: "drivee",
}

export const aggregatorsTypes = {
  [aggregatorsTypeKeys.YANDEX]: {
    id: 'yandex',
    label: 'Яндекс.Такси / Uber',
    translate: '',
    icons: [
        yandexIconNew,
        uberIcon
    ]
  },
  [aggregatorsTypeKeys.CITYMOBIL]: {
    id: 'citymobil',
    label: 'Cитимобил',
    translate: '',
    icons: [
      yandexIconNew,
      uberIcon
    ]
  },
  [aggregatorsTypeKeys.TAXOVICHKOF]: {
    id: 'taxovichkof',
    label: 'Таксовичкоф / Грузовичкоф / Cитимобил',
    translate: '',
    icons: [
      citymobilIcon,
      taxovichkofIcon,
      gruzovichkofIcon
    ]
  },
  [aggregatorsTypeKeys.BIBI]: {
    id: 'bibi',
    label: 'BiBi car',
    translate: 'BiBi car',
    icons: [
        bibiIcon
    ],
    data_icons: [
        bibiIconWhite
    ]
  },
  [aggregatorsTypeKeys.DRIVEE]: {
    id: 'drivee',
    label: 'Drivee',
    translate: 'Drivee',
    icons: [
        driveeIcon
    ]
  }
}
