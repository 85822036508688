import React, { useState } from 'react';
import styled from 'styled-components';
import SideMenu from '../SideMenu/SideMenu';
import {
  EuiIcon,
} from '@elastic/eui';
import { RoundedCloseFlyout } from 'components/Flyouts';

const SideMenuWrapper = styled.div`
  padding: 23px 20px 20px;
  overflow: auto;
`;

const MenuIcon = styled(EuiIcon)`
  color: ${props => props.$iconColor || props.theme.colors.colorEmptyShade};
`;

export default (props) => {
  const { isInitialOpen, iconColor } = props
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(isInitialOpen)
  let sideMenu

  const onCloseFlyout = () => setIsSideMenuVisible(false)
  const onToggleFlyout = () => setIsSideMenuVisible(!isSideMenuVisible)

  if (isSideMenuVisible) {
    sideMenu = (
      <RoundedCloseFlyout
        side='left'
        maxWidth={226}
        outsideClickCloses
        onClose={onCloseFlyout}>
        <SideMenuWrapper>
          <SideMenu />
        </SideMenuWrapper>
      </RoundedCloseFlyout>
    );
  }
  return (
    <>
      <MenuIcon
        type='menu'
        $iconColor={iconColor}
        onClick={onToggleFlyout} />
      {sideMenu}
    </>
  )
}