
import React, { useState } from 'react'
import styled from 'styled-components';
import {
  EuiAvatar,
  EuiPopover,
  EuiIcon,
  EuiContextMenuItem,
} from '@elastic/eui';
import { PrimaryButton } from 'components/Buttons';
import { useRouter } from 'utils/useRouter';

const PopoverBody = styled.div`
  width: 300px;
  @media ${props => props.theme.media.phoneSmall} {
    width: 230px;
  }
`;

const PopoverHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  line-height: 21px;
  position: relative;
  &:before {
    content: '';
    background-color: ${props => props.theme.colors.colorLightShade};
    height: 1px;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  a {
    color: ${props => props.theme.colors.colorMediumShade};
  }
`;

const ProfileAvatar = styled(EuiAvatar)`
  cursor: pointer;
  background-color: ${props => props.theme.colors.colorVis0};
`;

const MenuItem = styled(EuiContextMenuItem)`
  padding: 11px 12px;
  font-weight: 500;
  position: relative;
  svg {
    margin-right: 16px;
  }
  &:before {
    content: '';
    background-color: ${props => props.theme.colors.lighterShade};
    height: 1px;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  &:focus {
    background-color: transparent;
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
  &:last-of-type {
    border-bottom: none;
    &:before {
      display: none;
    }
  }
`;

const MenuItemInner = styled.div`
  display: flex;
  align-items: center;
`;

const PopoverEmail = styled.div`
  padding: 9px 12px;
  color: ${props => props.theme.colors.colorDisabledText};
  border-bottom: 1px solid ${props => props.theme.colors.lighterShade};
`;

const PopoverFooter = styled.div`
  padding: 2px 12px 12px;
`;

export default (props) => {
    const { isInitialOpen, userProfile, logout } = props
    const [isOpen, setIsOpen] = useState(isInitialOpen)

    const router = useRouter()

    const onToggleClick = () => setIsOpen((isOpen) => !isOpen)
    const onClose = () => setIsOpen(false)

    const button = (
      <button onClick={onToggleClick}>
        <ProfileAvatar
          type='space'
          name={userProfile?.full_name?.split(' ').map(n => n[0]).join('')}
          color='plain'
          size='s' />
      </button>
    )

    const onClickMenuItem = (path) => () => router.history.push(path)

    return (
      <EuiPopover
        button={button}
        isOpen={isOpen}
        offset={17}
        panelPaddingSize={'none'}
        anchorPosition='downRight'
        repositionOnScroll
        closePopover={onClose}>
        <PopoverBody>
          <PopoverHeader>
            <strong>{userProfile?.full_name}</strong>
          </PopoverHeader>
          <div>
            <PopoverEmail>
              <strong>{userProfile?.email}</strong>
            </PopoverEmail>
            <MenuItem
              onClick={onClickMenuItem('/settings/login')}
            >
              <MenuItemInner>
                <EuiIcon type='pencil' />Редактировать профиль
              </MenuItemInner>
            </MenuItem>
            <MenuItem
              onClick={onClickMenuItem('/settings/carpark')}
            >
              <MenuItemInner>
                <EuiIcon type='gear' />Настройки
              </MenuItemInner>
            </MenuItem>
            <MenuItem
              onClick={onClickMenuItem('/subscription')}
            >
              <MenuItemInner>
                <EuiIcon type='starEmpty' />Оплата подписки
              </MenuItemInner>
            </MenuItem>
            <PopoverFooter>
              <PrimaryButton
                size='s'
                fullWidth
                onClick={logout}
              >
                Выйти из сервиса
              </PrimaryButton>
            </PopoverFooter>
          </div >
        </PopoverBody>
      </EuiPopover>
    )
  }