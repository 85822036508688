import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from './store/store';
import '@elastic/eui/dist/eui_theme_amsterdam_light.css';
import NestedRoutes from "./utils/NestedRoutes";
import routes from "./configs/routes";
import * as appActions from "./store/app/appActions";
import { Toasts } from "./utils/toast";
import {Preloader} from "./containers/Preloader/Preloader";
import * as Sentry from '@sentry/browser';
import {UndefinedError} from './containers/UndefinedError/UndefinedError';
import {EngineeringWorks} from './containers/EngineeringWorks/EngineeringWorks';
import {ServerError} from './containers/ServerError/ServerError';
import {appErrors} from './store/app/model/appErrors'
import {supportWidget} from "./utils/supportWidget"
import 'moment/locale/ru';
import YandexMetrica from 'utils/YandexMetrica';

Sentry.init({dsn: "https://bd1a53d528a74544b543ae5f3958cf8d@sentry.mozen.tech/5"});

store.dispatch(appActions.initApp())

function resize() {
  const width = window.innerWidth
  let zoom = 1
  if (width >= 1024) {
    zoom = 1
  } else if (width <= 768) {
    zoom = 1
  } else {
    zoom = width / 1024
  }
  document.documentElement.style.setProperty('--page-zoom', zoom)
}

resize()
window.removeEventListener('resize', resize)
window.addEventListener('resize', resize)

const errors = {
  [appErrors.UNDEFINED_ERROR]: {
    component: <UndefinedError />
  },
  [appErrors.CONNECTION_ERROR]: {
    component: ''
  },
  [appErrors.ENGINEERING_WORKS]: {
    component: <EngineeringWorks />
  },
  [appErrors.SERVER_ERROR]: {
    component: <ServerError />
  }
}

class App extends Component {
  componentDidCatch(error) {
    store.dispatch(appActions.setError({error: appErrors.UNDEFINED_ERROR}))
  }
  componentDidMount() {
    // supportWidget()
  }

  render() {
    const isReady = this.props.app.isReady
    const appError = this.props.app.error
    return (
      <>
        {appError ? (
          errors[appError]?.component
        ) : isReady ? (
          <NestedRoutes routes={routes} match={{path: ''}}/>
        ) : (
          <Preloader />
        )}
        <YandexMetrica />
        <Toasts />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.app
    ,
  };
};

export default connect(mapStateToProps)(App)
