import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const initChatra = () => {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  if (isDev) return
  const script = document.createElement('script')
  script.innerHTML = `
      (function(d, w, c) {
        w.ChatraID = '${process.env.REACT_APP_CHATRA_ID}';
        var s = d.createElement('script');
        w[c] = w[c] || function() {
            (w[c].q = w[c].q || []).push(arguments);
        };
        s.async = true;
        s.src = 'https://call.chatra.io/chatra.js';
        if (d.head) d.head.appendChild(s);
      })(document, window, 'Chatra');
      window.ChatraSetup = {
        groupId: '${process.env.REACT_APP_CHATRA_GROUP_ID}'
      }`
  script.async = true
  document.body.appendChild(script)
}

function Chatra() {
  const user = useSelector((state) => state.user)
  const park = useSelector((state) => state.park)

  const detailsData = park.details?.data
  const parkID = detailsData?.park_id
  const bankType = detailsData?.bank_type
  const subscriptionStatus = detailsData?.subscription_status

  const userProfile = user.profile
  const email = userProfile.email
  const phone = userProfile.phone
  const name = userProfile.full_name

  useEffect(() => {
    initChatra()
  }, [])

  useEffect(() => {
    const chatra = window?.Chatra
    if (typeof chatra === 'function') {
      chatra('updateIntegrationData', {
        name,
        email,
        phone,
      })
    }
    return () => {
      const chatra = window?.Chatra
      if (typeof chatra === 'function') {
        window.Chatra('closeChat')
      }
    }
  }, [name, email, phone])
  return null
}

export default Chatra