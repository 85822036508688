import styled from 'styled-components';
import { EuiButtonIcon } from '@elastic/eui';

export const IconEmptyButton = styled(EuiButtonIcon)`
  color: inherit;
  &.euiButtonIcon {
    &:focus-within, &:hover {
      background-color: transparent;
    }
  }
`;
