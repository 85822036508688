import appActionTypes from "./appActionTypes";

export function initApp() {
  return {
    type: appActionTypes.APP_INIT,
    payload: {},
  }
}

export function setError({error}) {
  return {
    type: appActionTypes.APP_SET_ERROR,
    payload: {error},
  }
}