import React from 'react';
import styled from 'styled-components';
import {
  EuiIcon,
  EuiTitle
} from '@elastic/eui';

const Wrapper = styled.div`
  box-shadow: 0px 0.9px 4px -1px rgba(109, 152, 184, 0.08), 0px 2.6px 8px -1px rgba(109, 152, 184, 0.06), 0px 5.7px 12px -1px rgba(109, 152, 184, 0.05), 0px 15px 15px -1px rgba(109, 152, 184, 0.04);
  border-radius: 6px;
  padding: 16px 24px 16px 16px;
  display: flex;
  justify-content: space-between;
  @media ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    padding: 20px 16px;
  }
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  column-gap: 16px;
  @media ${({ theme }) => theme.media.phone} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const Icon = styled(EuiIcon)`
  align-self: flex-start;
  width:28px;
  height: 28px;
  @media ${({ theme }) => theme.media.phone} {
    margin-right: 0;
    width: 40px;
    height: 40px;
  }
`;

const Content = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.media.phone} {
    flex-direction: column-reverse;
    gap: 4px;
  }
`;

const Title = styled(EuiTitle)`
  @media ${({ theme }) => theme.media.phone} {
    font-size: 19px;
    line-height: 28px;
  }
`;

const Subtitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.colorDarkShade};
  @media ${({ theme }) => theme.media.phone} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  @media ${({ theme }) => theme.media.phone} {
    display: block;
    margin-top: 16px;
  }
`;

export const HorizontalCard = (props) => {
  const { className, title, icon, subtitle, footer } = props
  return (
    <Wrapper
      className={className}
    >
      <Body>
        {typeof icon === 'string' ? <Icon type={icon} size='original' /> : icon}
        <Content
          className='cardContent'
        >
          <Subtitle>
            {subtitle}
          </Subtitle>
          <Title>
            <h3>{title}</h3>
          </Title>
        </Content>
      </Body>
      <Footer>
        {footer}
      </Footer>
    </Wrapper>
  )
}