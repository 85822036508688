import {takeLatest} from "@redux-saga/core/effects";
import analyticsActionTypes from "../analytics/analyticsActionTypes";
import { call, put, all } from "redux-saga/effects"
import {network} from "../network";
import parkActionTypes from "../park/parkActionTypes";


function* fetchWeekdayWithdraws (action) {
    const { dateStart, dateEnd } = action.payload;

    try {
        const response = yield call(network.appAxios, {
            url: '/erp/2/park/statistics/weekday-withdraw',
            method: 'GET',
            params: {
                date_start: dateStart.format('YYYY-MM-DD'),
                date_end: dateEnd.format('YYYY-MM-DD'),
            }
        })

        yield put({
            type: analyticsActionTypes.WEEKDAY_WITHDRAW_FULFILLED,
            payload: response.data
        });
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: analyticsActionTypes.WEEKDAY_WITHDRAW_FAIL,
                payload: { accessDenied: true },
            })
            return
        }
    }
}

function* fetchIncomeReport (action) {
    const { dateStart, dateEnd } = action.payload;

    try {
        const response = yield call(network.appAxios, {
            url: '/erp/1/park/income-report',
            method: 'GET',
            params: {
                date_start: dateStart.format('YYYY-MM-DD'),
                date_end: dateEnd.format('YYYY-MM-DD'),
                report_format: 'json'
            }
        })


        yield put({
            type: analyticsActionTypes.INCOME_REPORT_FULFILLED,
            payload: response.data
        });
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "PermissionDenied") {
            yield put({
                type: analyticsActionTypes.INCOME_REPORT_FAIL,
                payload: { accessDenied: true },
            })
            return
        }
    }
}

function* fetchFeesStatistics (action) {
    const { dateStart, dateEnd } = action.payload;

    try {
        const response = yield call(network.appAxios, {
            url: '/erp/2/park/statistics/fees',
            method: 'GET',
            params: {
                date_start: dateStart.format('YYYY-MM-DD'),
                date_end: dateEnd.format('YYYY-MM-DD'),
            }
        });

        yield put({
            type: analyticsActionTypes.INCOME_FEES_STATISTICS_FULFILLED,
            payload: response.data
        });
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: analyticsActionTypes.INCOME_FEES_STATISTICS_FAIL,
                payload: { accessDenied: true },
            })
            return
        }
    }
}

export default function* analyticsSagas() {
    yield all([
        yield takeLatest(analyticsActionTypes.WEEKDAY_WITHDRAW_REQUEST, fetchWeekdayWithdraws),
        yield takeLatest(analyticsActionTypes.INCOME_REPORT_REQUEST, fetchIncomeReport),
        yield takeLatest(analyticsActionTypes.INCOME_FEES_STATISTICS_REQUEST, fetchFeesStatistics),
    ])
}