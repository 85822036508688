import React from 'react';
import styled from 'styled-components';
import {
  EuiButton,
} from '@elastic/eui';

export const LightBrownButton = styled(props => <EuiButton color='warning' {...props} />)`
  background-color: ${props => props.theme.colors.brownBackgroundLighter};
  color: ${props => props.theme.colors.colorBrown};
`;
