import {useBreakpoints} from "../../utils/useBreakpoints";

export default ({children, postfix, className, ...rest}) => {
    const hrefStart = 'https://wiki.mozen.io/ru_RU/';
    const { isMobile } = useBreakpoints()

    return  (
        <a className={className} href={hrefStart + postfix + '?utm_source=erp' + (isMobile ? '&utm_medium=mobile' : '')} {...rest}>
        {children}
        </a>
    );
}