import analyticsActionTypes from './analyticsActionTypes';

const initialState = {
    weekdayWithdraw: {
        accessDenied: false,
        data: null,
        total: null,
        loading: false
    },
    incomeReport: {
        accessDenied: false,
        data: null,
        loading: false
    },
    feesStatistics: {
        accessDenied: false,
        data: null,
        loading: false
    }
};

const reducers = {
    [analyticsActionTypes.WEEKDAY_WITHDRAW_REQUEST] : (state, payload) => {
        return {
            ...state,
            weekdayWithdraw: {
                loading: true,
                data: null,
                total: null
            }
        }
    },
    [analyticsActionTypes.WEEKDAY_WITHDRAW_FULFILLED]: (state, payload) => {
        return {
            ...state,
            weekdayWithdraw: {
                ...state.weekdayWithdraw,
                accessDenied: false,
                loading: false,
                data: payload.weekday_withdraws,
                total: payload.total
            }
        }
    },
    [analyticsActionTypes.WEEKDAY_WITHDRAW_FAIL]: (state, payload) => {
        console.log({
            ...state,
            weekdayWithdraw: {
                ...state.weekdayWithdraw,
                accessDenied: payload?.accessDenied,
                loading: false,
                data: [],
                total: null
            }
        });

        return {
            ...state,
            weekdayWithdraw: {
                ...state.weekdayWithdraw,
                accessDenied: payload?.accessDenied,
                loading: false,
                data: [],
                total: null
            }
        }
    },
    [analyticsActionTypes.INCOME_REPORT_REQUEST] : (state, payload) => {
        return {
            ...state,
            incomeReport: {
                loading: true,
                data: null,
            }
        }
    },
    [analyticsActionTypes.INCOME_REPORT_FULFILLED]: (state, payload) => {
        return {
            ...state,
            incomeReport: {
                ...state.incomeReport,
                accessDenied: false,
                loading: false,
                data: payload
            }
        }
    },
    [analyticsActionTypes.INCOME_REPORT_FAIL]: (state, payload) => {
        return {
            ...state,
            incomeReport: {
                ...state.incomeReport,
                accessDenied: payload?.accessDenied,
                loading: false,
                data: null
            }
        }
    },
    [analyticsActionTypes.INCOME_FEES_STATISTICS_REQUEST] : (state, payload) => {
        return {
            ...state,
            feesStatistics: {
                loading: true,
                data: null,
            }
        }
    },
    [analyticsActionTypes.INCOME_FEES_STATISTICS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            feesStatistics: {
                ...state.feesStatistics,
                accessDenied: false,
                loading: false,
                data: payload
            }
        }
    },
    [analyticsActionTypes.INCOME_FEES_STATISTICS_FAIL]: (state, payload) => {
        return {
            ...state,
            feesStatistics: {
                ...state.feesStatistics,
                accessDenied: payload?.accessDenied,
                loading: false,
                data: null
            }
        }
    },
};

export default function analyticsReducer(state = initialState, action) {
    if (reducers[action.type]) {
        return reducers[action.type](state, action.payload)
    }
    return state
};
