const employeesActionTypes = {
  EMPLOYEE_UPDATE_REQUEST: 'EMPLOYEE_UPDATE_REQUEST',
  EMPLOYEE_UPDATE_FULFILLED: 'EMPLOYEE_UPDATE_FULFILLED',
  EMPLOYEE_UPDATE_FAIL: 'EMPLOYEE_UPDATE_FAIL',

  EMPLOYEE_UPDATE_STATUS_REQUEST: 'EMPLOYEE_UPDATE_STATUS_REQUEST',
  EMPLOYEE_UPDATE_STATUS_FULFILLED: 'EMPLOYEE_UPDATE_STATUS_FULFILLED',
  EMPLOYEE_UPDATE_STATUS_FAIL: 'EMPLOYEE_UPDATE_STATUS_FAIL',

  EMPLOYEE_FETCH_REQUEST: 'EMPLOYEE_FETCH_REQUEST',
  EMPLOYEE_FETCH_FULFILLED: 'EMPLOYEE_FETCH_FULFILLED',
  EMPLOYEE_FETCH_FAIL: 'EMPLOYEE_FETCH_FAIL',

  EMPLOYEE_CREATE_REQUEST: 'EMPLOYEE_CREATE_REQUEST',
  EMPLOYEE_CREATE_FULFILLED: 'EMPLOYEE_CREATE_FULFILLED',
  EMPLOYEE_CREATE_FAIL: 'EMPLOYEE_CREATE_FAIL',

  EMPLOYEES_FETCH_LIST_REQUEST: 'EMPLOYEES_FETCH_LIST_REQUEST',
  EMPLOYEES_FETCH_LIST_FULFILLED: 'EMPLOYEES_FETCH_LIST_FULFILLED',
  EMPLOYEES_FETCH_LIST_FAIL: 'EMPLOYEES_FETCH_LIST_FAIL',

  EMPLOYEES_FETCH_LOGS_REQUEST: 'EMPLOYEES_FETCH_LOGS_REQUEST',
  EMPLOYEES_FETCH_LOGS_FULFILLED: 'EMPLOYEES_FETCH_LOGS_FULFILLED',
  EMPLOYEES_FETCH_LOGS_FAIL: 'EMPLOYEES_FETCH_LOGS_FAIL',

  EMPLOYEES_FETCH_LOGS_BY_ID_REQUEST: 'EMPLOYEES_FETCH_LOGS_BY_ID_REQUEST',
  EMPLOYEES_FETCH_LOGS_BY_ID_FULFILLED: 'EMPLOYEES_FETCH_LOGS_BY_ID_FULFILLED',
  EMPLOYEES_FETCH_LOGS_BY_ID_FAIL: 'EMPLOYEES_FETCH_LOGS_BY_ID_FAIL',

}

export default employeesActionTypes
