const receiptActionTypes = {
  RECEIPT_FETCH_SNO_REQUEST: 'RECEIPT_FETCH_SNO_REQUEST',
  RECEIPT_FETCH_SNO_FULFILLED: 'RECEIPT_FETCH_SNO_FULFILLED',
  RECEIPT_FETCH_SNO_FAIL: 'RECEIPT_FETCH_SNO_FAIL',

  RECEIPT_FETCH_CLOUD_CASHBOX_REQUEST: 'RECEIPT_FETCH_CLOUD_CASHBOX_REQUEST',
  RECEIPT_FETCH_CLOUD_CASHBOX_FULFILLED: 'RECEIPT_FETCH_CLOUD_CASHBOX_FULFILLED',
  RECEIPT_FETCH_CLOUD_CASHBOX_FAIL: 'RECEIPT_FETCH_CLOUD_CASHBOX_FAIL',
  
  RECEIPT_CREATE_CLOUD_CASHBOX_REQUEST: 'RECEIPT_CREATE_CLOUD_CASHBOX_REQUEST',
  RECEIPT_CREATE_CLOUD_CASHBOX_FULFILLED: 'RECEIPT_CREATE_CLOUD_CASHBOX_FULFILLED',
  RECEIPT_CREATE_CLOUD_CASHBOX_FAIL: 'RECEIPT_CREATE_CLOUD_CASHBOX_FAIL',
    
  RECEIPT_UPDATE_CLOUD_CASHBOX_REQUEST: 'RECEIPT_UPDATE_CLOUD_CASHBOX_REQUEST',
  RECEIPT_UPDATE_CLOUD_CASHBOX_FULFILLED: 'RECEIPT_UPDATE_CLOUD_CASHBOX_FULFILLED',
  RECEIPT_UPDATE_CLOUD_CASHBOX_FAIL: 'RECEIPT_UPDATE_CLOUD_CASHBOX_FAIL',
      
  RECEIPT_BLOCK_CLOUD_CASHBOX_REQUEST: 'RECEIPT_BLOCK_CLOUD_CASHBOX_REQUEST',
  RECEIPT_BLOCK_CLOUD_CASHBOX_FULFILLED: 'RECEIPT_BLOCK_CLOUD_CASHBOX_FULFILLED',
  RECEIPT_BLOCK_CLOUD_CASHBOX_FAIL: 'RECEIPT_BLOCK_CLOUD_CASHBOX_FAIL',
}

export default receiptActionTypes
