import appActionTypes from "./appActionTypes";

const initialState = {
  isReady: false,
  error: undefined,
}

const reducers = {
  [appActionTypes.APP_INIT]: (state) => {
    return {...initialState}
  },
  [appActionTypes.APP_READY]: () => {
    return {
      isReady: true
    }
  },
  [appActionTypes.APP_SET_ERROR]: (state, payload) => {
    return {
      ...state,
      error: payload.error
    }
  }
}

export default function appReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
