import React, { useMemo } from "react";
import routes from "../configs/routes";
import { useRouter } from "./useRouter";
import { matchPath } from "react-router";

function normalizePath(path) {
  if (Array.isArray(path)) {
    return path.map(item => normalizePath(item));
  }
  return path.replace('//', '/');
}

function buildBreadcrumbs(routes, path, crumbs) {
  const list = []

  routes.forEach(route => {
    const newPath = normalizePath(`${path}${route.path}`)
    const newCrumbs = route.breadcrumb ? [...crumbs, route.breadcrumb] : crumbs

    if(route.routes) {
      list.push(...buildBreadcrumbs(route.routes, newPath, newCrumbs))
    }

    list.push({
      path: newPath,
      crumbs: newCrumbs,
    })
  })

  return list
}

function BreadcrumbsData(props) {
  const router = useRouter()
  const bread = useMemo(() => buildBreadcrumbs(routes, '/', []), [])

  const match = bread.find(bread => {
    return matchPath(router.pathname, {
      path: bread.path,
      exact: true,
      strict: false
    });
  })

  if(match) {
    return <props.component breadcrumbs={match.crumbs} truncate={false} />
  }

  return null
}

export {BreadcrumbsData};
