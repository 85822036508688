import React from 'react';
import styled from 'styled-components';
import {
  EuiFlyout,
} from '@elastic/eui';
import { useSelector } from 'react-redux';

const Wrapper = styled(EuiFlyout)`
  top: ${({ $topOffset }) => $topOffset}px;
  height: calc(100vh ${({ $topOffset }) => `- ${$topOffset}px`});
  box-shadow: ${({ theme }) => theme.boxShadows.leftMedium};
  background-color: ${({ theme }) => theme.colors.colorEmptyShade};
  max-height: calc(100vh ${({ $topOffset }) => `- ${$topOffset}px`});
  
  @media ${props => props.theme.media.tablet} {
    height: auto;
    max-height: unset;
  }
  
  @media ${props => props.theme.media.phoneSmall} {
    max-width: 95vw !important;
  }
`;

export const Flyout = (props) => {
  const { children, onClose, ...rest } = props
  const park = useSelector((state) => state.park)
  const promoHeight = park.promo.height

  const topOffset = promoHeight > 0 ? promoHeight + 48 : 48
  const onCloseFlyout = () => setTimeout(onClose)

  return (
    <Wrapper
      $topOffset={topOffset}
      onClose={onCloseFlyout}
      {...rest}
    >
      {children}
    </Wrapper>
  )
}
