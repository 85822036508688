export const driverWorkStatus = {
  "working": {
    id: 'working',
    translate: 'Активен'
  },
  "fired": {
    id: 'fired',
    translate: 'Уволен'
  },
   "not_working": {
    id: 'not_working',
    translate: 'Не Работает'
  },
}