const analyticsActionTypes = {
    WEEKDAY_WITHDRAW_REQUEST: 'WEEKDAY_WITHDRAW_REQUEST',
    WEEKDAY_WITHDRAW_FULFILLED: 'WEEKDAY_WITHDRAW_FULFILLED',
    WEEKDAY_WITHDRAW_FAIL: 'WEEKDAY_WITHDRAW_FAIL',

    INCOME_REPORT_REQUEST: 'INCOME_REPORT_REQUEST',
    INCOME_REPORT_FULFILLED: 'INCOME_REPORT_FULFILLED',
    INCOME_REPORT_FAIL: 'INCOME_REPORT_FAIL',

    INCOME_FEES_STATISTICS_REQUEST: 'INCOME_FEES_STATISTICS_REQUEST',
    INCOME_FEES_STATISTICS_FULFILLED: 'INCOME_STATISTICS_FEES_FULFILLED',
    INCOME_FEES_STATISTICS_FAIL: 'INCOME_FEES_STATISTICS_FAIL'
};

export default analyticsActionTypes;