import { declinationOfNumber } from "./declinationOfNumber"
import { regExp } from "./formats"

const declensionSymbols = value => declinationOfNumber(value, ['символ', 'символа', 'символов'])

export const validation = {
  composeValidators: (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined),

  someFilled: values => Array.isArray(values) && values.some(value => value),
  required: value => value || value === 0 ? undefined : "Обязательное поле",
  minLength: min => value => typeof value !== "string" || value.length >= min ? undefined : `от ${min} символов`,
  maxLength: max => value => typeof value !== "string" || value.length <= max ? undefined : `до ${max} символов`,
  maxInt: (max, errorMessage = '') => value => typeof value !== "string" || !max || value <= max ? undefined : (errorMessage || `Значение не должно быть больше ${max}`),
  minInt: (min, errorMessage = '') => value => typeof value !== "string" || !min || value >= min ? undefined : (errorMessage || `Значение не должно быть меньше ${min}`),
  isInt: value => (/^\d+$/.test(value) ? undefined : "Значение должно быть целым числом"),
  isLength: (value, message) => value.length ? undefined : message ? message : "required",
  isEmail: value => typeof value !== "string" || regExp.email.test(value) ? undefined : 'Неверный формат',
  name: value => !!value && !regExp.name.test(value) ? 'Неверный формат' : undefined,
  driverContract: value => !!value && !regExp.driverContract.test(value) ? 'Неверный формат' : undefined,
  minLengthField: min => value => typeof value !== "string" || value.length >= min ? undefined : `Минимальная длина ${min} ${declensionSymbols(min)}`,
  maxLengthField: max => value => typeof value !== "string" || value.length <= max ? undefined : `Максимальная длина ${max} ${declensionSymbols(max)}`,
  maxLengthMessage: max => value => typeof value !== "string" || value.length <= max ? undefined : `Максимальная длина сообщения ${max} ${declensionSymbols(max)}`,
  commonName: value => !!value && !regExp.commonName.test(value) ? 'Неверный формат' : undefined,
  phone: value => !regExp.phone.test(value) ? 'Неверный формат номера телефона' : undefined,
  validateOgrn: value => {
    let errorText = 'Неверный формат ОГРН';

    if (typeof value === 'number') {
      value = value.toString();
    } else if (typeof value !== 'string') {
      value = '';
    }

    if (!value.length) {
      return errorText;
    } else if (/[^0-9]/.test(value)) {
      return errorText;
    } else if (value.length !== 13) {
      return errorText;
    } else {
      let n13 = parseInt((parseInt(value.slice(0, -1)) % 11).toString().slice(-1));

      if (n13 !== parseInt(value[12])) {
        return errorText;
      }
    }

    return undefined;
  },
  validateOgrnip: (ogrnip) => {
    let result = 'Неверный формат ОГРНИП';

    if (typeof ogrnip === 'number') {
      ogrnip = ogrnip.toString();
    } else if (typeof ogrnip !== 'string') {
      ogrnip = '';
    }

    if (!ogrnip.length) {
      return result;
    } else if (/[^0-9]/.test(ogrnip)) {
      return result;
    } else if (ogrnip.length !== 15) {
      return result;
    } else {
      let n15 = parseInt((parseInt(ogrnip.slice(0, -1)) % 13).toString().slice(-1));

      if (n15 !== parseInt(ogrnip[14])) {
        return result;
      }
    }

    return undefined;
  },
  uuid: (errorMessage = '') => value => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value) ?  undefined : (errorMessage || `Значение должно быть валидным uuid`),
}
