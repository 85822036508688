export const bankTypes = {
  'alfa': 'alfa',
  'alfa_e2c': 'alfa_e2c',
  'azon': 'azon',
  'qiwi': 'qiwi',
  'modul': 'modul',
  'manual': 'manual',
  'tinkoff': 'tinkoff',
  'tochka': 'tochka',
  'payselection': 'payselection',
  'sbank': 'sbank'
}
