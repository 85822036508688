import { transactionPaymentStatus } from "./transactionPaymentStatus";
import { transactionReasons } from "./transactionReasons";

export const transactionTabFilters = {
  'all': {
    payment_status: [

    ],
    emptyPaymentStatus: [
      transactionPaymentStatus.authorized,
      transactionPaymentStatus.pending,
      transactionPaymentStatus.acknowledged,
      transactionPaymentStatus.succeeded,
      transactionPaymentStatus.declined,
      transactionPaymentStatus.failed,
      transactionPaymentStatus.processing,
    ],
    reason_type: [

    ],
    emptyReasonType: [
      transactionReasons.park_driver_withdraw,
      transactionReasons.taxometer_manual_topup,
      transactionReasons.taximeter_topup,
      transactionReasons.taximeter_refund,
      transactionReasons.taxometer_referral_topup,
      // {
      //   id: `${idPrefix}paymentType4`,
      //   label: 'Аренда авто',
      // },
      transactionReasons.park_driver_withdraw_commission,
      transactionReasons.driver_withdraw_fuel,
    ],
  },
  'confirmation': {
    payment_status: [
      transactionPaymentStatus.pending,
    ],
    emptyPaymentStatus: [

    ],
    reason_type: [
      // transactionReasons.park_driver_withdraw,
      // transactionReasons.driver_fuel_card_topup,
      // transactionReasons.taxometer_referral_topup,
    ],
    emptyReasonType: [
      transactionReasons.park_driver_withdraw,
      transactionReasons.taxometer_referral_topup,
      transactionReasons.driver_withdraw_fuel
    ],
  },
  'suspicious': {
    // todo: suspicious filters
    payment_status: [],
    reason_type: [],
    emptyReasonType: [],
  },
  'not-completed': {
    payment_status: [
      transactionPaymentStatus.not_completed,
    ],
    emptyPaymentStatus: [

    ],
    reason_type: [
      // transactionReasons.park_driver_withdraw,
      // transactionReasons.driver_fuel_card_topup,
    ],
    emptyReasonType: [
      transactionReasons.park_driver_withdraw,
      transactionReasons.driver_withdraw_fuel
    ],
  },
}
