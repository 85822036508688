import autoregActionTypes from './autoregActionTypes';

const initialState = {
    taxiDriverWorkRules: {
        loading: true,
        data: null,
    },
    walkingCourierWorkRules: {
        loading: true,
        data: null,
    },
    drivingCourierWorkRules: {
        loading: true,
        data: null,
    },
    workRules: {
        data: null,
        loading: false,
        'driver': {
            data: null,
            loading: false
        },
        'walking-courier': {
            data: null,
            loading: false
        },
        'auto-courier': {
            data: null,
            loading: false
        }
    },
    // settings: {
    //     'driver': {
    //         aggregatorName: null,
    //         workRuleName: null
    //     },
    //     'walking-courier': {
    //         aggregatorName: null,
    //         workRuleName: null
    //     },
    //     'auto-courier': {
    //         aggregatorName: null,
    //         workRuleName: null
    //     }
    // },
    settingsInitiated: false,
    settings: [],
    accessDenied: false,
    drivers: {
        loading: false,
        totalAmount: 0,
        list: [],
        counters: [],
        details: null,
        approve: {
            loading: false
        },
        decline: {
            loading: false
        },
    },
};

const reducers = {
    [autoregActionTypes.WORK_RULES_REQUEST] : (state, {rulesType}) => {
        return {
            ...state,
            [rulesType]: {
                loading: true,
                data: null,
            },
            walkingCourierWorkRules: {
                loading: true,
                data: null,
            },
            drivingCourierWorkRules: {
                loading: true,
                data: null,
            },
            workRules: {
                ...state.workRules,
                loading: true,
                data: null,
                [rulesType]: {
                    loading: true,
                    data: null,
                },
            }
        }
    },
    [autoregActionTypes.WORK_RULES_FULFILLED]: (state, {rulesType, data}) => {
        return {
            ...state,
            accessDenied: false,
            workRules: {
                ...state.workRules,
                loading: false,
                data: data.work_rules,
                [rulesType]: {
                    loading: false,
                    data: data.work_rules,
                }
            }
        }
    },
    [autoregActionTypes.WORK_RULES_FAIL]: (state, {rulesType, accessDenied = null}) => {
        return {
            ...state,
            accessDenied: accessDenied,
            workRules: {
                ...state.workRules,
                loading: false,
                data: [],
                [rulesType]: {
                    loading: false,
                    data: [],
                }
            }
        }
    },
    [autoregActionTypes.UPDATE_WORK_RULES_FULFILLED]: (state, payload) => {
        return {
            ...state,
            accessDenied: false
        }
    },
    [autoregActionTypes.UPDATE_WORK_RULES_FAIL]: (state, payload) => {
        return {
            ...state,
            accessDenied: payload?.accessDenied
        }
    },
    [autoregActionTypes.FETCH_DRIVERS_REQUEST]: (state, payload) => {
        return {
            ...state,
            drivers: {
                ...state.drivers,
                loading: true,
                list: []
            }
        }
    },
    [autoregActionTypes.FETCH_DRIVERS_FAIL]: (state, payload) => {
        return {
            ...state,
            drivers: {
                ...state.drivers,
                loading: false,
                list: []
            }
        }
    },
    [autoregActionTypes.FETCH_DRIVERS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            drivers: {
                ...state.drivers,
                loading: false,
                list: payload.drivers,
                totalAmount: payload.total_amount
            }
        }
    },
    [autoregActionTypes.CONFIRM_DRIVER_REQUEST_REQUEST]: (state, payload) => {
        return {
            ...state,
            drivers :{
                ...state.drivers,
                approve: {
                    loading: true
                }
            }
        }
    },
    [autoregActionTypes.CONFIRM_DRIVER_REQUEST_FULFILLED]: (state, payload) => {
        return {
            ...state,
            drivers : {
                ...state.drivers,
                approve: {
                    loading: false
                }
            }
        }
    },
    [autoregActionTypes.CONFIRM_DRIVER_REQUEST_FAIL]: (state, payload) => {
        return {
            ...state,
            drivers : {
                ...state.drivers,
                approve: {
                    loading: false
                }
            }
        }
    },
    [autoregActionTypes.DECLINE_DRIVER_REQUEST_REQUEST]: (state, payload) => {
        return {
            ...state,
            drivers : {
                ...state.drivers,
                decline: {
                    loading: true
                }
            }
        }
    },
    [autoregActionTypes.DECLINE_DRIVER_REQUEST_FULFILLED]: (state, payload) => {
        return {
            ...state,
            drivers : {
                ...state.drivers,
                decline: {
                    loading: false
                }
            }
        }
    },
    [autoregActionTypes.DECLINE_DRIVER_REQUEST_FAIL]: (state, payload) => {
        return {
            ...state,
            drivers : {
                ...state.drivers,
                decline: {
                    loading: false
                }
            }
        }
    },
    [autoregActionTypes.FETCH_DRIVER_BY_PHONE_REQUEST]: (state, payload) => {
        return {
            ...state,
            drivers : {
                ...state.drivers,
                details: null
            }
        }
    },
    [autoregActionTypes.FETCH_DRIVER_BY_PHONE_FULFILLED]: (state, payload) => {
        return {
            ...state,
            drivers : {
                ...state.drivers,
                details: payload
            }
        }
    },
    [autoregActionTypes.FETCH_DRIVER_BY_PHONE_FAIL]: (state, payload) => {
        return {
            ...state,
            drivers : {
                ...state.drivers,
                details: null
            }
        }
    },
    [autoregActionTypes.SAVE_SELECTED_SETTINGS]: (state, settings) => {
        return {
            ...state,
            settingsInitiated: true,
            settings
        }
    },
    [autoregActionTypes.FETCH_COUNTERS_REQUEST]: (state, payload) => {
        return {
            ...state,
            drivers: {
                ...state.drivers,
                counters: []
            }
        }
    },
    [autoregActionTypes.FETCH_COUNTERS_FAIL]: (state, payload) => {
        return {
            ...state,
            drivers: {
                ...state.drivers,
                counters: []
            }
        }
    },
    [autoregActionTypes.FETCH_COUNTERS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            drivers: {
                ...state.drivers,
                counters: payload.counters
            }
        }
    },
};

export default function analyticsReducer(state = initialState, action) {
    if (reducers[action.type]) {
        return reducers[action.type](state, action.payload)
    }
    return state
};
