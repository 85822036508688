import subscriptionActionTypes from "./subscriptionActionTypes";

const initialState = {
  summary: {
    loading: true,
    data: null,
  },
  payments: {
    loading: false,
    data: null,
    accessDenied: false
  }
}

const reducers = {
  [subscriptionActionTypes.SUBSCRIPTION_SUMMARY_REQUEST]: (state, payload) => {
    return {
      ...state,
      summary: {
        ...state.summary,
        loading: true,
        data: null,
      }
    }
  },
  [subscriptionActionTypes.SUBSCRIPTION_SUMMARY_FULFILLED]: (state, payload) => {
    return {
      ...state,
      summary: {
        ...state.summary,
        loading: false,
        data: payload.summary,
      }
    }
  },
  [subscriptionActionTypes.SUBSCRIPTION_SUMMARY_FAIL]: (state, payload) => {
    return {
      ...state,
      summary: {
        ...state.summary,
        loading: false,
        data: null,
      }
    }
  },

  [subscriptionActionTypes.SUBSCRIPTION_FETCH_PAYMENTS_REQUEST]: (state, payload) => {
    return {
      ...state,
      payments: {
        ...state.payments,
        loading: true,
        data: null,
      }
    }
  },
  [subscriptionActionTypes.SUBSCRIPTION_FETCH_PAYMENTS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      payments: {
        ...state.payments,
        loading: false,
        data: payload.payments,
        accessDenied: false
      }
    }
  },
  [subscriptionActionTypes.SUBSCRIPTION_FETCH_PAYMENTS_FAIL]: (state, payload) => {
    return {
      ...state,
      payments: {
        ...state.payments,
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },
}

export default function subscriptionReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
