
import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  EuiPopover,
  EuiTitle,
} from '@elastic/eui';

const PopoverBody = styled.div`
  width: 154px;
  line-height: 21px;
  color: ${props => props.theme.colors.colorEmptyShade};
`;

const GlobalStyle = createGlobalStyle`
  .balancePanel.euiPanel {
    background-color: ${props => props.theme.colors.darkShade};
    box-shadow: 0px 1px 5px rgba(109, 152, 184, 0.1), 0px 3.6px 13px rgba(109, 152, 184, 0.07), 0px 8.4px 23px rgba(109, 152, 184, 0.06), 0px 23px 35px rgba(109, 152, 184, 0.05);
    .euiPopover__panelArrow.euiPopover__panelArrow--bottom {
      &:after {
        border-bottom: 12px solid ${props => props.theme.colors.darkShade};
      }
    }
  }
`;

const BalanceTitle = styled(EuiTitle)`
  font-size: 16px;
  color: ${props => props.theme.colors.colorEmptyShade};
`;

const Cents = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.colorDisabledText};
`;

export default (props) => {
  const { balanceAmount } = props
  const [isOpen, setIsOpen] = useState(false)
  const onButtonClick = () => setIsOpen((isOpen) => !isOpen)
  const onClose = () => setIsOpen(false)

  const balanceAmountPenny = Math.abs(balanceAmount) % 100
  const balanceAmountValue = (balanceAmount > 0 ? Math.floor(balanceAmount / 100) : Math.ceil(balanceAmount / 100))?.toLocaleString('ru')

  const amount = <>₽ {balanceAmountValue}<Cents>, {balanceAmountPenny?.toString().padStart(2, '0')}</Cents></>
  const amountDescription = balanceAmount < 0 ? 'Рекомендуем пополнить баланс' : 'У вас на расчетном счете в банке'

  const button = (
    <button onClick={onButtonClick}>
      <BalanceTitle
        size='s'
      >
        <h3>{!!balanceAmount && amount}</h3>
      </BalanceTitle>
    </button>
  )

  if (!balanceAmount) {
    return null
  }

  return (
    <>
      <GlobalStyle />
      <EuiPopover
        button={button}
        isOpen={isOpen}
        offset={17}
        repositionOnScroll
        panelPaddingSize='s'
        anchorPosition='downCenter'
        panelClassName='balancePanel'
        closePopover={onClose}>
        <PopoverBody>
          {amountDescription}
        </PopoverBody>
      </EuiPopover>
    </>
  )
}