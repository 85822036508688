import React from 'react'
import styled from "styled-components"
import {
  EuiLoadingSpinner,
  EuiTitle,
} from '@elastic/eui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > * {
    max-width: ${({$maxWidth}) => `${$maxWidth}px` || '100%'};
  }
  > * {
    &:nth-child(2) {
      margin: 8px 0 16px;
    }
  }
  @media ${({ theme }) => theme.media.phone} {
    > * {
      &:nth-child(2) {
        margin: 8px 0 10px;
      }
    }
  }
`;

const Spinner = styled(EuiLoadingSpinner)`
  margin-bottom: 35px;
  @media ${({ theme }) => theme.media.phone} {
    margin-bottom: 8px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.colorDarkShade};
  @media ${({ theme }) => theme.media.phone} {
    font-size: 14px;
  }
`;

const Title = styled(EuiTitle)`
  @media ${({ theme }) => theme.media.phone} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const BaseLoading = (props) => {
  const { title, description, hideSpinner, maxWidth, className } = props
  const text = description || 'Ваш запрос обрабатывается. Пожалуйста, подождите...'
  return (
    <Wrapper
      className={className}
      $maxWidth={maxWidth}
    >
      {!hideSpinner &&
        <Spinner
          size='xl'
        />
      }
      {title &&
        <Title size='s'>
          <h3>{title}</h3>
        </Title>
      }
      <Text>
        {text}
      </Text>
    </Wrapper>
  )
}


