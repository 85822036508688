import { useEffect } from 'react';
import { useRouter } from './useRouter';

function YandexMetrica() {
  const router = useRouter()
  const pathname = router.location.pathname

  useEffect(() => {
    const ym = window?.ym
    if (ym) {
      ym(process.env.REACT_APP_YANDEX_METRICA, 'hit', window.location.href)
    }
  }, [pathname])
  return null
}

export default YandexMetrica