import React from 'react';
import styled from 'styled-components';
import {
  EuiCard,
  EuiIcon
} from '@elastic/eui';

const Wrapper = styled(EuiCard)`
  background-color: ${({ theme }) => theme.colors.colorEmptyShade};
  box-shadow: ${({ theme }) => theme.boxShadows.bottomLight};
  padding: 20px 16px;
  .euiCard__children {
    margin-top: 4px;
    line-height: 21px;
  }
  .euiCard__top + .euiCard__content {
    margin-top: 22px;
  }
  .euiTitle {
    font-size: 19px;
  }
  .euiCard__top .euiCard__icon {
    margin-top: 0;
  }
  
  @media ${({ theme }) => theme.media.phone} {
    flex-flow: row wrap;
    column-gap: 20px;
    .euiCard__top {
      flex: 0;
      order: 2;
    }
    .euiCard__content {
      flex: 1;
      order: 1;
    }
    .euiCard__top + .euiCard__content {
      margin-top: 0;
    }
    .euiCard__footer {
      order: 3;
    }
  }
`;

const Icon = styled(EuiIcon)`
  height: 40px;
`;

export const Card = (props) => {
  const { className, title, icon, body, footer } = props
  return (
    <Wrapper
      className={className}
      icon={typeof icon === 'string' ? <Icon size='original' type={icon} /> : icon}
      title={title}
      textAlign='left'
      paddingSize='none'
      display
      children={body}
      footer={footer}
    />
  )
}