import { promiseListener } from "store/store";
import transactionsActionTypes from "./transactionsActionTypes";

export const transactionsActions = {

  fetch(args) {
    return {
      type: transactionsActionTypes.TRANSACTIONS_FETCH_REQUEST,
      payload: args,
    }
  },

  asyncConfirm(args) {
    return promiseListener.createAsyncFunction({
      start: transactionsActionTypes.TRANSACTIONS_CONFIRM_REQUEST,
      resolve: transactionsActionTypes.TRANSACTIONS_CONFIRM_FULFILLED,
      reject: transactionsActionTypes.TRANSACTIONS_CONFIRM_FAIL,
    }).asyncFunction(args)
  },

  createReport(args) {
    return {
      type: transactionsActionTypes.TRANSACTIONS_CREATE_REPORT_REQUEST,
      payload: args,
    }
  },

  asyncCreatePaymentOrder(args) {
    return promiseListener.createAsyncFunction({
      start: transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_REQUEST,
      resolve: transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_FULFILLED,
      reject: transactionsActionTypes.TRANSACTIONS_CREATE_PAYMENT_ORDER_FAIL,
    }).asyncFunction(args)
  },

  fetchCounters() {
    return {
      type: transactionsActionTypes.TRANSACTIONS_FETCH_COUNTERS_REQUEST,
      payload: {},
    }
  },
  storePaymentDetails(payload) {
    return {
      type: transactionsActionTypes.STORE_PAYMENT_DETAILS,
      payload
    }
  },
  fetchChannelLimits(payload) {
    return {
      type: transactionsActionTypes.TRANSACTIONS_FETCH_CHANNEL_LIMITS_REQUEST,
      payload: payload,
    }
  },
}
