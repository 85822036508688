import { driverWorkStatus } from "./driverWorkStatus"
import { driverStatus } from "./driverStatus"

export const driverTabFilter = {
  'all': {
    work_statuses: [],
    statuses: [
      driverStatus.registered
    ]
  },
  'working': {
    work_statuses: [
      driverWorkStatus.working
    ],
    statuses: [
      driverStatus.registered
    ]
  },
  'fired': {
    work_statuses: [
      driverWorkStatus.fired
    ]
  }
}