import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NestedRoutes from "../../utils/NestedRoutes";

class EmptyLayout extends Component {
  render() {
    return <NestedRoutes routes={this.props.routes} match={this.props.match}/>
  }
}

export default connect()(withRouter(EmptyLayout));
