import { all, put, takeEvery, take } from 'redux-saga/effects'
import appActionTypes from "./appActionTypes";
import userActionTypes from "../user/userActionTypes";

function* init() {
  yield put({
    type: userActionTypes.USER_RESTORE,
    payload: {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    }
  })

  yield take([
    userActionTypes.USER_AUTHENTICATION_FULFILLED,
    userActionTypes.USER_AUTHENTICATION_FAIL,
  ])

  yield put({
    type: appActionTypes.APP_READY,
    payload: {},
  })

}

export default function* appSagas() {
  yield all([
    yield takeEvery(appActionTypes.APP_INIT, init),
  ])
}
